import React from "react";
import Dashboard from "../pages/Dashboard";

const root = "/dashboard";

const dashboard = [
  {
    path: `${root}/`,
    component: <Dashboard />,
    navbar: true,
    sidebar: true,
  },
];

export default dashboard;
