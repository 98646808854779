import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import AddCustomer from "./_components/AddCustomer";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Nama Pelanggan",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"capitalize"}>
        {value}
      </Typography>
    ),
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Email",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "no_telpon",
    headerClassName: "header",
    headerName: "Telepon",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "address",
    headerClassName: "header",
    headerName: "Alamat",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6, textTransform: "capitalize" }}>
        {value.address_detail}
      </span>
    ),
  },
];

const CustomerList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getCustomers = () => {
    axiosInstance
      .get("/customers", authentication)
      .then((res) => {
        // console.log(res.data);
        setCustomers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <Box flex="1">
      <AddCustomer
        open={modalOpen}
        setModalOpen={setModalOpen}
        getCustomers={getCustomers}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar Pelanggan
        </Typography>
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#0C78BD",
              borderRadius: "20px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Data Pelanggan
          </Button>
        )}
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={customers}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(rows) => navigate(`/master/customers/${rows.id}`)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomerList;
