import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "./routes/main";
import React, { useState, useEffect } from "react";
import LoginPage from "../src/pages/Login";
import ChangePassword from "pages/Password";

function App() {
  const [login, setLogin] = useState({
    token: JSON.parse(localStorage.getItem("userToken")),
  });

  const theme = createTheme({
    typography: {
      fontFamily: ["Murecho"].join(","),
    },
  });

  useEffect(() => {
    setLogin({ token: JSON.parse(localStorage.getItem("userToken")) });
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* {login.token ? <Main /> : <LoginPage />} */}
        {login.token ? (
          <Main />
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/forget-password?token=:id"
              element={<ChangePassword />}
            />
          </Routes>
        )}
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
