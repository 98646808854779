import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

function Card({ id, value, label, icon, path }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: 450,
        height: 80,
        backgroundColor: "white",
        fontFamily: "Murecho",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "primary.white",
          opacity: [0.9, 0.8, 0.7],
        },
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        paddingX: 2,
        justifyContent: "start",
      }}
      key={id}
      onClick={() => navigate(path)}
    >
      <span style={{ color: "#0E78BC", fontSize: "35px", marginTop: "5px" }}>
        {icon}
      </span>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 2,
        }}
      >
        <p
          style={{
            fontSize: "12px",
            marginBottom: "1px",
            fontStyle: "Murecho",
            fontWeight: 400,
            color: "#333835",
            marginTop: 0,
          }}
        >
          {label}
        </p>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          {value}
        </p>
      </Box>
    </Box>
  );
}

export default Card;
