import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";
import StatusSwitch from "../../../components/Switch";
import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import EditOffIcon from "@mui/icons-material/EditOff";

const PaymentDetails = ({
  open,
  setOpenDetail,
  getPayments,
  detail,
  transactions,
}) => {
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [banks, setBanks] = useState([]);
  const [paymentType, setPaymentType] = useState("Transfer");
  const [status, setStatus] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();
  const values = [
    {
      _id: "01",
      name: "Aktif",
      value: true,
    },
    {
      _id: "02",
      name: "Non-aktif",
      value: true,
    },
  ];

  const handleClose = () => {
    setOpenDetail(false);
    setIsEdit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      payment_type: paymentType,
      status: status,
      bank_code: selectedBank,
      transaction_id: selectedTransaction,
    };

    axiosInstance
      .put(`/payments/${detail._id}`, data, authentication)
      .then(() => {
        getPayments();
        setIsEdit(false);
        setOpenDetail(false);
        Swal.fire("Selesai!", "Data Pembayaran telah diperbaharui!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletePayment = () => {
    setOpenDetail(false);
    Swal.fire({
      title: "Hapus Pembayaran?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenDetail(false);
        axiosInstance
          .delete(`/payments/${detail._id}`, authentication)
          .then(() => {
            getPayments();
            setOpenDetail(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data Pembayaran telah terhapus!", "success");
      } else {
        setOpenDetail(true);
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/payments/${detail._id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status pembayaran berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getPayments();
        setOpenDetail(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBanks = () => {
    axiosInstance
      .get("/banks?type=customers", authentication)
      .then((res) => {
        // console.log(res);
        setBanks(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <>
      {detail && (
        <ModalCustom
          open={open}
          handleClose={() => setOpenDetail(false)}
          cardStyle={{ borderRadius: 2, width: 700, height: "auto" }}
        >
          {/* Header */}
          <Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" gap={2} justifyContent="space-between">
                <Typography fontSize="16px" fontWeight="600">
                  Detail Pembayaran
                </Typography>
                <Stack direction={"row"}>
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleClose()}
                  />
                </Stack>
              </Stack>
            </Box>
            <Divider />
          </Box>

          {isEdit ? null : (
            <Stack
              direction="row"
              justifyContent="right"
              paddingX={3}
              paddingY={1}
            >
              <Stack
                sx={{
                  width: "fit-content",
                  borderRadius: "10px",
                  backgroundColor: `${
                    detail.status === true ? "#DEF8E9" : "#FBDFDF"
                  }`,
                }}
                justifyContent="space-between"
                direction="row"
                px={1}
                py={1}
              >
                {detail.status === true ? (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#155C33",
                      fontWeight: "bold",
                      paddingTop: "1.5px",
                    }}
                  >
                    Aktif
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#C41717",
                      fontWeight: "bold",
                      paddingTop: "1.5px",
                    }}
                  >
                    Non-aktif
                  </Typography>
                )}

                <Stack sx={{ py: "5px" }}>
                  <StatusSwitch
                    status={!!detail.status}
                    changeStatus={changeStatus}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}

          {/* Body */}
          <Box flex="1">
            <Stack paddingX={3} paddingY={2}>
              {isEdit ? (
                <>
                  <form onSubmit={handleSubmit}>
                    <Stack paddingBottom={3}>
                      <Grid item xs={12} paddingBottom={2}>
                        <CustomTextField
                          name="partner_id"
                          label="Jenis Pembayaran"
                          value={paymentType}
                          // onChange={(e) => setPartnerId(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} paddingBottom={2}>
                        <CustomTextField
                          name="title"
                          label="Nama bank"
                          placeholder="Masukkan nama bank"
                          select
                          value={selectedBank}
                        >
                          {banks?.map((bank) => (
                            <MenuItem
                              key={bank._id}
                              value={`${bank.name} - ${bank.bank_name} - ${bank.no_rek}`}
                              onClick={() =>
                                setSelectedBank(
                                  `${bank.name} - ${bank.bank_name} - ${bank.no_rek}`,
                                )
                              }
                            >
                              {bank.name} - {bank.bank_name} - {bank.no_rek}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                      {/* <Grid item xs={12} paddingBottom={2}>
                        <CustomTextField
                          name="title"
                          label="Pilih transaksi"
                          placeholder="Masukkan nama bank"
                          select
                          value={selectedTransaction}
                        >
                          {transactions.map((transaction) => (
                            <MenuItem
                              key={transaction._id}
                              value={`${transaction._id}`}
                              onClick={() =>
                                setSelectedTransaction(`${transaction._id}`)
                              }
                            >
                              {transaction?.reference_no} -{" "}
                              {transaction.buyer_data.name}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid> */}
                      <Grid item xs={12}>
                        <CustomTextField
                          name="status"
                          label="Status"
                          select
                          value={status}
                        >
                          {values.map((v) => (
                            <MenuItem
                              key={v._id}
                              value={v.value}
                              onClick={() => setStatus(v.value)}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                    </Stack>
                    {/* <Divider /> */}
                    <Box>
                      <Box
                        sx={{
                          pl: 2,
                        }}
                      >
                        <Stack direction="row" justifyContent="right">
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              marginRight: "16px",
                              borderRadius: "20px",
                            }}
                            variant="outlined"
                            onClick={() =>
                              isEdit ? setIsEdit(false) : handleClose()
                            }
                          >
                            {isEdit ? "Batal" : "Tutup"}
                          </Button>
                          {isEdit ? (
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                backgroundColor: "#0C78BC",
                                borderRadius: "20px",
                              }}
                              type="submit"
                              variant="contained"
                            >
                              Simpan perubahan
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                backgroundColor: "#0C78BC",
                                borderRadius: "20px",
                              }}
                              variant="contained"
                              onClick={() => setIsEdit(true)}
                            >
                              Edit
                            </Button>
                          )}
                          {isEdit ? null : (
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                backgroundColor: "#ee3f11",
                                marginLeft: "16px",
                                borderRadius: "20px",
                              }}
                              variant="contained"
                              onClick={() => deletePayment()}
                            >
                              Hapus
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </form>
                </>
              ) : (
                <Stack>
                  <Stack direction="row" paddingY={4} gap={2}>
                    <Grid
                      sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                    >
                      <Typography color={"#848786"}>No. Invoice</Typography>
                    </Grid>
                    <Stack sx={{ width: "70%" }} direction={"row"} gap={2}>
                      <Typography>{detail.transaction.reference_no}</Typography>
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#0C78BC",
                          borderRadius: "15px",
                          fontSize: "10px",
                          padding: 0.7,
                        }}
                        variant="contained"
                        onClick={() =>
                          navigate(
                            `/transactions/list/${detail.transaction._id}`,
                          )
                        }
                      >
                        Lihat Transaksi
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack direction="row" paddingBottom={4} gap={2}>
                    <Grid
                      sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                    >
                      <Typography color={"#848786"}>
                        Jenis Pembayaran
                      </Typography>
                    </Grid>
                    <Grid sx={{ width: "70%" }}>
                      <Typography>{detail.payment_type}</Typography>
                    </Grid>
                  </Stack>
                  <Stack direction="row" paddingBottom={4} gap={2}>
                    <Grid
                      sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                    >
                      <Typography color={"#848786"}>Status</Typography>
                    </Grid>
                    <Grid sx={{ width: "70%" }}>
                      {detail.status === false ? (
                        <button
                          style={{
                            color: "#C41717",
                            fontWeight: "600",
                            fontSize: "12px",
                            paddingX: "10px",
                            paddingY: "8px",
                            border: "none",
                            borderRadius: "25px",
                            backgroundColor: "#FBDFDF",
                          }}
                        >
                          <span>Non-aktif</span>
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "#155C33",
                            fontWeight: "600",
                            fontSize: "12px",
                            paddingX: "10px",
                            paddingY: "8px",
                            border: "none",
                            borderRadius: "25px",
                            backgroundColor: "#DEF8E9",
                          }}
                        >
                          <span>Aktif</span>
                        </button>
                      )}
                    </Grid>
                  </Stack>
                  <Stack direction="row" paddingBottom={2} gap={2}>
                    <Grid
                      sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                    >
                      <Typography color={"#848786"}>Bank</Typography>
                    </Grid>
                    <Grid sx={{ width: "70%" }}>
                      <Typography>{detail.bank_code}</Typography>
                    </Grid>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
          {isEdit ? null : (
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" justifyContent="right">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "16px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={() => (isEdit ? setIsEdit(false) : handleClose())}
                  >
                    {isEdit ? "Batal" : "Tutup"}
                  </Button>
                  {isEdit ? (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Simpan perubahan
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                  {isEdit ? null : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#ee3f11",
                        marginLeft: "16px",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => deletePayment()}
                    >
                      Hapus
                    </Button>
                  )}
                </Stack>
              </Box>
            </Box>
          )}
        </ModalCustom>
      )}
    </>
  );
};

export default PaymentDetails;
