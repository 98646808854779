import React from "react";
import FleetManagement from "../pages/Fleet";
import PartnerDetails from "../pages/Partners/_components/DetailPartner";

const root = "/partner";

const partner = [
  {
    path: `${root}/fleet`,
    component: <FleetManagement />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/details/:id`,
    component: <PartnerDetails />,
    sidebar: true,
    navbar: true,
  },
];

export default partner;
