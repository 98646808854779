import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
// import StatusSwitch from "components/Switch";
import AddPayment from "./_components/AddPayment";
import PaymentDetails from "./_components/PaymentDetails";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "payment_type",
    headerClassName: "header",
    headerName: "Jenis Pembayaran",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: (params) =>
      params.value === false ? (
        <button
          style={{
            color: "#C41717",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
            marginLeft: "6px",
          }}
        >
          <span>Non-aktif</span>
        </button>
      ) : (
        <button
          style={{
            color: "#155C33",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
            marginLeft: "6px",
          }}
        >
          <span>Aktif</span>
        </button>
      ),
  },
  {
    field: "bank_code",
    headerClassName: "header",
    headerName: "Bank",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "transaction",
    headerClassName: "header",
    headerName: "Invoice",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>{value?.reference_no}</span>
    ),
  },
];

const Payments = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  // const navigate = useNavigate();
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState(null);
  const [banks, setBanks] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getPayments = () => {
    axiosInstance
      .get("/payments", authentication)
      .then((res) => {
        // console.log(res);
        setPayments(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBanks = () => {
    axiosInstance
      .get("/banks?type=customers", authentication)
      .then((res) => {
        // console.log(res);
        setBanks(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransactions = () => {
    axiosInstance
      .get("/transactions", authentication)
      .then((res) => {
        // console.log(res);
        setAllTransactions(
          res.data.filter((r) => r.status_messsage !== "Batal"),
        );
        setTransactions(
          res.data.filter((r) => r.status_messsage === "Pending"),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const seeServiceDetail = (row) => {
    setPayment(row.row);
    setOpenDetail(true);
  };

  useEffect(() => {
    getPayments();
    getBanks();
    getTransactions();
  }, []);

  return (
    <>
      <Box flex="1">
        <AddPayment
          open={modalOpen}
          setModalOpen={setModalOpen}
          getPayments={getPayments}
          banks={banks}
          transactions={transactions}
        />
        <PaymentDetails
          open={openDetail}
          setOpenDetail={setOpenDetail}
          getPayments={getPayments}
          detail={payment}
          banks={banks}
          transactions={allTransactions}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Daftar Pembayaran
          </Typography>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#0C78BD",
              borderRadius: "20px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Pembayaran
          </Button>
        </Stack>
        <Stack>
          <Box
            sx={{
              "& .header": {
                backgroundColor: "#F9FAFA",
              },
              "& .header-font": {
                backgroundColor: "#F9FAFA",
                color: "#F9FAFA",
              },
              padding: 3,
            }}
            variant="contained"
          >
            <DataGrid
              rows={payments}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              getRowId={(rows) => rows._id}
              header
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 15, 25]}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              onRowClick={(row) => seeServiceDetail(row)}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Payments;
