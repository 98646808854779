import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import AddQuestion from "./_components/AddQuestion";
import QuestionDetails from "./_components/QuestionDetails";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "question",
    headerClassName: "header",
    headerName: "Pertanyaan",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "answer",
    headerClassName: "header",
    headerName: "Jawaban",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "author",
    headerClassName: "header",
    headerName: "Pembuat Pertanyaan",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
];

const FAQ = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  // const navigate = useNavigate();
  const [FAQs, setFAQs] = useState([]);
  const [question, setQuestion] = useState({});
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getFAQ = async () => {
    await axiosInstance
      .get("/faqs/", authentication)
      .then((res) => {
        // console.log(res);
        setFAQs(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFAQ();
  }, []);

  const openFAQDetail = (rows) => {
    setQuestion(rows.row);
    setOpenDetail(true);
  };

  return (
    <Box flex="1">
      <AddQuestion
        open={modalOpen}
        setModalOpen={setModalOpen}
        getFAQ={getFAQ}
      />
      <QuestionDetails
        isOpen={openDetail}
        setIsOpen={setOpenDetail}
        getFAQ={getFAQ}
        detail={question}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar FAQ
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#0C78BD",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Tambah FAQ
        </Button>
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={FAQs}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(row) => openFAQDetail(row)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default FAQ;
