import feeds from "./feeds";
import home from "./home";
import dashboard from "./dashboard";
import admin from "./admin";
import master from "./master";
import partner from "./partner";
import transactions from "./transactions";
import faq from "./faq";
import forgetPassword from "./forgetPassword";

const routes = [
  ...feeds,
  ...home,
  ...dashboard,
  ...admin,
  ...master,
  ...partner,
  ...transactions,
  ...faq,
  ...forgetPassword,
];

export default routes;
