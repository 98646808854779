import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tab, Tabs } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axiosInstance from "../../utils/api/api";
import AddBank from "./_components/AddBank";
import BankDetail from "./_components/BankDetail";
import CustomNoRowsOverlay from "../../components/NoRows";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  {
    field: "name",
    headerClassName: "header",
    headerName: "Nama Pemilik",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"capitalize"}>
        {value}
      </Typography>
    ),
  },
  {
    field: "bank_name",
    headerClassName: "header",
    headerName: "Bank",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "no_rek",
    headerClassName: "header",
    headerName: "Nomor Rekening",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
];

const partnerColumn = [
  {
    field: "partner",
    headerClassName: "header",
    headerName: "Nama Pemilik",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"capitalize"}>
        {value.name_company}
      </Typography>
    ),
  },
  {
    field: "bank_name",
    headerClassName: "header",
    headerName: "Bank",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "no_rek",
    headerClassName: "header",
    headerName: "Nomor Rekening",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
];

const Banks = () => {
  const [value, setValue] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [banks, setBanks] = useState([]);
  const [partnerBanks, setPartnerBanks] = useState([]);
  const [bank, setBank] = useState({});
  const [partners, setPartners] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getBanks = () => {
    axiosInstance
      .get("/banks?type=customers", authentication)
      .then((res) => {
        // console.log(res.data);
        setBanks(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPartnerBanks = () => {
    axiosInstance
      .get("/banks?type=partners", authentication)
      .then((res) => {
        // console.log(res.data);
        setPartnerBanks(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPartners = () => {
    axiosInstance
      .get("/partners/partner", authentication)
      .then((res) => {
        // console.log(res.data);
        setPartners(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openServiceDetails = (rows) => {
    setBank(rows.row);
    setOpenDetail(true);
  };

  useEffect(() => {
    getBanks();
    getPartners();
    getPartnerBanks();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flex="1">
      <AddBank
        open={modalOpen}
        setModalOpen={setModalOpen}
        getBanks={getBanks}
        getPartnerBanks={getPartnerBanks}
        value={value}
        partners={partners}
      />
      <BankDetail
        open={openDetail}
        setOpenDetail={setOpenDetail}
        getBanks={getBanks}
        getPartnerBanks={getPartnerBanks}
        detail={bank}
        value={value}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar Bank
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#0C78BD",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          {value === 0
            ? "Tambah Rekening Pelanggan"
            : "Tambah Rekening Partner"}
        </Button>
      </Stack>
      <Box sx={{ paddingX: 3 }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Pelanggan"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="Partner"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
              }}
            >
              <DataGrid
                rows={banks}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) => openServiceDetails(row)}
              />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
              }}
            >
              <DataGrid
                rows={partnerBanks}
                columns={partnerColumn}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) => openServiceDetails(row)}
              />
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Banks;
