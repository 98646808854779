import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";

const AddDriver = ({ open, setModalOpen, getDrivers, partnerId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [media, setMedia] = useState(null);
  const status = true;
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    setPhone("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("full_name", name);
    formData.append("email", email);
    formData.append("no_hp", phone);
    formData.append("partner_id", partnerId);
    formData.append("status", status);
    formData.append("driver_picture", media);

    axiosInstance
      .post("/driver", formData, authentication)
      .then((res) => {
        // console.log(res);
        getDrivers();
        setModalOpen(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Driver berhasil disimpan!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Driver Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingTop={2} alignItems="center">
            <label htmlFor="driver_picture">
              <input
                style={{ display: "none", cursor: "pointer" }}
                id="driver_picture"
                onChange={(e) => setMedia(e.target.files[0])}
                name="driver_picture"
                type="file"
              />
              <Avatar
                src={media ? window.URL.createObjectURL(media) : null}
                alt="Profile Picture"
                sx={{ height: "140px", width: "140px", cursor: "pointer" }}
              />
              {/* <Stack direction="row" justifyContent="right" paddingTop={2}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#0C78BC",
                      borderRadius: "20px",
                    }}
                    variant="contained"
                  >
                    Tambah Foto Profil
                  </Button>
                </Stack> */}
            </label>
          </Stack>
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="name"
                  label="Nama lengkap"
                  placeholder="Masukkan nama lengkap driver"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="phone"
                  label="Nomor WhatsApp"
                  placeholder="Masukkan nomor WhatsApp driver"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="email"
                  label="Alamat email"
                  placeholder="Masukkan email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddDriver;
