import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import axios from "axios";
import PROVINCES_URL from "../../../utils/api/provinces";
import Swal from "sweetalert2";

const EditCustomer = ({ open, setModalOpen, getCustomer, customer }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const status = true;
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState("");
  const [province, setProvince] = useState("Aceh");
  const [city, setCity] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const getPartners = () => {
    axiosInstance
      .get("/partners/partner", authentication)
      .then((res) => {
        // console.log(res.data);
        setPartners(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProvince = async () => {
    await axios
      .get(PROVINCES_URL)
      .then((res) => {
        setProvinceList(res.data.provinsi);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetCity = (e) => {
    axios
      .get(
        `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${e}`,
      )
      .then((res) => {
        setCityList(res.data.kota_kabupaten);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetSubdistrict = (e) => {
    axios
      .get(
        `https://dev.farizdotid.com/api/daerahindonesia/kecamatan?id_kota=${e}`,
      )
      .then((res) => {
        setSubDistrictList(res.data.kecamatan);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetPostalCode = (e) => {
    axios
      .get(`https://kodepos.vercel.app/search/?q=${e}`)
      .then((res) => {
        setPostalCodeList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      full_name: name,
      email: email,
      province: province,
      city: city,
      sub_district: subDistrict,
      postal_code: postalCode,
      address_detail: detailAddress,
      no_telpon: phone,
      status: status,
      partner_id: partner,
    };

    axiosInstance
      .put(`/customers/${customer._id}`, data, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Data Pelanggan berhasil diperbaharui!",
          showConfirmButton: false,
          timer: 2000,
        });
        getCustomer();
        setModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    getProvince();
    getPartners();
  }, []);

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 1000, height: 650 }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Edit Data Pelanggan
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingBottom={2}>
            <Stack paddingTop={2} direction="row" gap={4}>
              <Grid container xs={12} alignContent="flex-start">
                <Typography variant="h6" py={1}>
                  Profil
                </Typography>
                <Grid item xs={12}>
                  <CustomTextField
                    name="province"
                    label="Pilih Partner"
                    select
                    value={partner}
                  >
                    {partners.map((p) => (
                      <MenuItem
                        key={p._id}
                        value={p._id}
                        onClick={() => setPartner(p._id)}
                      >
                        {p.name_company}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="name"
                    label="Nama lengkap"
                    placeholder={customer.full_name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="phone"
                    label="Telepon"
                    placeholder={customer.no_telpon}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="email"
                    label="Alamat email"
                    placeholder={customer.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack paddingTop={1}>
              <Grid container xs={6} alignContent="flex-start">
                <Typography variant="h6" py={1}>
                  Alamat
                </Typography>
              </Grid>
            </Stack>
            <Stack direction="row" gap={4}>
              <Grid container xs={6} alignContent="flex-start">
                <Grid item xs={12}>
                  <CustomTextField
                    name="province"
                    label="Pilih provinsi"
                    placeholder="Masukkan provinsi"
                    select
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                  >
                    {provinceList.map((p) => (
                      <MenuItem
                        key={p.id}
                        value={p.nama}
                        onClick={() => actionSetCity(p.id)}
                      >
                        {p.nama}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="city"
                    label="Pilih kota"
                    placeholder="Pilih kota"
                    select
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {cityList.map((c) => (
                      <MenuItem
                        key={c.id}
                        value={c.nama}
                        onClick={() => actionSetSubdistrict(c.id)}
                      >
                        {c.nama}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="kecamatan"
                    label="Pilih kecamatan"
                    placeholder="Masukkan kecamatan"
                    select
                    value={subDistrict}
                    onChange={(e) => setSubDistrict(e.target.value)}
                  >
                    {subDistrictList.map((s) => (
                      <MenuItem
                        key={s.id}
                        value={s.nama}
                        onClick={() => actionSetPostalCode(s.nama)}
                      >
                        {s.nama}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              </Grid>
              <Grid container xs={6} alignContent="flex-start">
                <Grid item xs={12}>
                  <CustomTextField
                    name="postal_code"
                    label="Pilih kode pos"
                    placeholder="Masukkan kode pos"
                    select
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  >
                    {postalCodeList.map((p) => (
                      <MenuItem key={p.urban} id={p.urban} value={p.postalcode}>
                        {p.postalcode} - {p.urban}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="detail_address"
                    label="Detail lainnya"
                    placeholder="Masukkan detail alamat (nama jalan, lokasi, RT/RW, dsb.)"
                    onChange={(e) => setDetailAddress(e.target.value)}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default EditCustomer;
