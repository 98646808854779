import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import AddPartner from "./_components/AddPartner";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  // {
  //   field: "partner_id",
  //   headerClassName: "header",
  //   headerName: "ID",
  //   width: 80,
  //   renderCell: ({ value }) => <Typography noWrap fontSize={14}>
  // },
  {
    field: "name_company",
    headerClassName: "header",
    headerName: "Nama Partner",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "address",
    headerClassName: "header",
    headerName: "Alamat",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>{value.city}</span>
    ),
  },
  {
    field: "no_telpon",
    headerClassName: "header",
    headerName: "Telepon",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "owner_name",
    headerClassName: "header",
    headerName: "Pemilik",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: (params) =>
      params.value === false ? (
        <button
          style={{
            color: "#C41717",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
            marginLeft: "6px",
          }}
        >
          <span>Non-aktif</span>
        </button>
      ) : (
        <button
          style={{
            color: "#155C33",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
            marginLeft: "6px",
          }}
        >
          <span>Aktif</span>
        </button>
      ),
  },
];

const PartnerList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getPartners = () => {
    axiosInstance
      .get(
        user.role === "AdminPartner"
          ? `/partners/partner/${user.partner_id}`
          : "/partners/partner",
        authentication,
      )
      .then((res) => {
        // console.log(res.data);
        setPartners(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <Box flex="1">
      <AddPartner
        open={modalOpen}
        setModalOpen={setModalOpen}
        getPartners={getPartners}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar Partner
        </Typography>
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#0C78BD",
              borderRadius: "20px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Partner Baru
          </Button>
        )}
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={partners}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(rows) => navigate(`/partner/details/${rows.id}`)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default PartnerList;
