import React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 4,
};

const ModalCustom = ({ open, handleClose, children, cardStyle = {} }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, ...cardStyle }}>{children}</Box>
    </Modal>
  );
};

export default ModalCustom;
