import React, { useState } from "react";
import {
  Stack,
  Box,
  Button,
  Avatar,
  Typography,
  ClickAwayListener,
  Fade,
  Popper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

function Navbar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const roboUrl = "https://robohash.org/";
  const disanitasiLogo =
    "https://ik.imagekit.io/6tebrod5l/disanitasi/Logo_KiHiMY5Ba.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677907016043";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const user = JSON.parse(localStorage.getItem("admin_data"));

  const Logout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
    return window.location.reload();
  };

  return (
    <Stack
      height="56px"
      width="100%"
      direction="row"
      justifyContent="space-between"
      sx={{
        position: "fixed",
        top: 0,
        color: "#10448E",
        backgroundColor: "#FFF",
        zIndex: "100",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box
          width="240px"
          paddingX=".5rem"
          sx={{ backgroundColor: "#FFF", borderTopRightRadius: "10px" }}
        >
          <Stack direction="row" alignItems="center" height="100%" spacing={1}>
            <Avatar
              src={disanitasiLogo}
              sx={{ borderRadius: 0, height: "60px", width: "60px" }}
            />
            {/* <Typography fontSize="15px" color="white" fontWeight="600">
              Disanitasi
            </Typography> */}
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          paddingRight="54px"
          paddingY={1}
        >
          <Stack sx={{ color: "#222" }}>
            <Typography sx={{ fontSize: "10px", textTransform: "uppercase" }}>
              {user.role === "SuperAdmin"
                ? "Super Admin"
                : user.role === "AdminDisanitasi"
                ? "Admin Disanitasi"
                : user.role === "AdminPartner"
                ? "Partner Admin"
                : user.role === "BlogAdmin"
                ? "Blog Admin"
                : "Admin IPLT"}
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>{user.full_name}</Typography>
          </Stack>
          <Avatar
            src={`${roboUrl}${user.full_name}`}
            sx={{
              background: "linear-gradient(180deg, #0e78bc 0%, #000066 100%)",
              cursor: "pointer",
            }}
            aria-describedby={id}
            onClick={handleClick}
          />
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Fade {...TransitionProps} timeout={350}>
                  <Box
                    sx={{
                      p: 2,
                      mr: 2,
                      mt: 1,
                      width: "150px",
                      borderRadius: "4px",
                      bgcolor: "background.paper",
                      boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14)",
                    }}
                  >
                    <Stack>
                      <Stack>
                        {/* <Stack direction="row">
                          <Stack mr={2}>
                            <Avatar
                              src={`${roboUrl}${user?.full_name}`}
                              sx={{
                                backgroundColor: `#10838E`,
                                width: "72px",
                                height: "72px",
                              }}
                              aria-describedby={id}
                              onClick={handleClick}
                            />
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{ fontWeight: 600, fontSize: "18px", mt: 1 }}
                            >
                              {user.full_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "#6D7688",
                              }}
                            >
                              {user.role}
                            </Typography>
                          </Stack>
                        </Stack> */}
                        <Stack>
                          {/* <Button
                            sx={{
                              textTransform: "capitalize",
                              height: "30px",
                            }}
                            variant="outlined"
                            onClick={() =>
                              navigate(`../admin/${user.full_name}`, {
                                replace: true,
                              })
                            }
                            startIcon={<AccountCircleOutlinedIcon />}
                          >
                            Profile
                          </Button> */}
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              height: "30px",
                              mt: 1,
                            }}
                            variant="outlined"
                            color="error"
                            onClick={Logout}
                            startIcon={<LogoutIcon />}
                          >
                            Logout
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Navbar;
