import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";

const AddPayment = ({
  open,
  setModalOpen,
  getPayments,
  banks,
  transactions,
}) => {
  const [selectedBank, setSelectedBank] = useState("");
  const [paymentType, setPaymentType] = useState("Transfer");
  const [status, setStatus] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const values = [
    {
      _id: "0",
      name: "Aktif",
      value: true,
    },
    {
      _id: "1",
      name: "Non-aktif",
      value: false,
    },
  ];

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      payment_type: paymentType,
      status: status,
      bank_code: selectedBank,
      transaction_id: selectedTransaction,
    };

    axiosInstance
      .post("/payments", data, authentication)
      .then((res) => {
        // console.log(res);
        getPayments();
        setModalOpen(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Data pembayaran berhasil disimpan!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Pembayaran
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Grid item xs={12} paddingBottom={2}>
              <CustomTextField
                name="partner_id"
                label="Jenis Pembayaran"
                value={paymentType}
                // onChange={(e) => setPartnerId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
              {banks && (
                <CustomTextField
                  name="title"
                  label="Nama bank"
                  placeholder="Masukkan nama bank"
                  select
                  value={selectedBank}
                >
                  {banks.map((bank) => (
                    <MenuItem
                      key={bank._id}
                      value={`${bank.name} - ${bank.bank_name} - ${bank.no_rek}`}
                      onClick={() =>
                        setSelectedBank(
                          `${bank.name} - ${bank.bank_name} - ${bank.no_rek}`,
                        )
                      }
                    >
                      {bank.name} - {bank.bank_name} - {bank.no_rek}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
              <CustomTextField
                name="title"
                label="Pilih transaksi"
                placeholder="Masukkan nama bank"
                select
                value={selectedTransaction}
              >
                {transactions.map((transaction) => (
                  <MenuItem
                    key={transaction._id}
                    value={`${transaction._id}`}
                    onClick={() => setSelectedTransaction(`${transaction._id}`)}
                  >
                    {transaction.reference_no} -{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        paddingLeft: "4px",
                      }}
                    >
                      {transaction.buyer_data.name}
                    </span>
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="status"
                label="Status"
                select
                value={status}
              >
                {values.map((v) => (
                  <MenuItem
                    key={v._id}
                    value={v.value}
                    onClick={() => setStatus(v.value)}
                  >
                    {v.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddPayment;
