import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddDriver from "./_components/AddDriver";
import DetailDriver from "./_components/DetailDriver";
import API_URL from "../../utils/api/base";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Nama Driver",
    width: 200,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "driver_picture",
    headerClassName: "header",
    headerName: "Profil",
    width: 200,
    renderCell: ({ value }) => (
      <img
        alt="Profile"
        style={{ height: "45px", width: "45px", borderRadius: "100px" }}
        crossOrigin="anonymous"
        src={`${API_URL}${value}`}
      />
    ),
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Usia",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "no_hp",
    headerClassName: "header",
    headerName: "Telepon",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: (params) =>
      params.value === true ? (
        <button
          style={{
            color: "#155C33",
            fontWeight: "600",
            fontSize: "12px",
            paddingX: "10px",
            paddingY: "8px",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
            marginLeft: "6px",
          }}
        >
          <span>Aktif</span>
        </button>
      ) : (
        <button
          style={{
            color: "#C41717",
            fontWeight: "600",
            fontSize: "12px",
            paddingX: "10px",
            paddingY: "8px",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
            marginLeft: "6px",
          }}
        >
          <span>Non-aktif</span>
        </button>
      ),
  },
];

const DriverList = ({ drivers, partnerId, getDrivers }) => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  // const [drivers, setDrivers] = useState([]);
  const [driverData, setDriverData] = useState({});
  const token = JSON.parse(localStorage.getItem("userToken"));
  const [seeDriver, setSeeDriver] = useState(false);
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const openDriverModal = (row) => {
    setDriverData(row.row);
    setSeeDriver(true);
  };

  return (
    <Box flex="1">
      <AddDriver
        open={modalOpen}
        setModalOpen={setModalOpen}
        getDrivers={getDrivers}
        partnerId={partnerId}
      />
      <DetailDriver
        seeDriver={seeDriver}
        setSeeDriver={setSeeDriver}
        driverData={driverData}
        partnerId={partnerId}
        getDrivers={getDrivers}
      />
      <Stack direction="row" justifyContent="space-between" paddingY={2}>
        <Typography variant="h6" fontWeight="500">
          Daftar Driver
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#0C78BD",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Tambah Driver
        </Button>
      </Stack>
      {drivers ? (
        <Stack>
          <Box
            sx={{
              "& .header": {
                backgroundColor: "#F9FAFA",
              },
              "& .header-font": {
                backgroundColor: "#F9FAFA",
                color: "#F9FAFA",
              },
            }}
          >
            <DataGrid
              rows={drivers}
              columns={columns}
              pageSize={pageSize}
              autoHeight
              getRowId={(rows) => rows._id}
              isRowSelectable={false}
              header
              pagination
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 15, 25]}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              onRowClick={(row) => openDriverModal(row)}
            />
          </Box>
        </Stack>
      ) : (
        "No data"
      )}
    </Box>
  );
};

export default DriverList;
