import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
// import EditIcon from "@mui/icons-material/Edit";
// import EditOffIcon from "@mui/icons-material/EditOff";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import StatusSwitch from "../../../components/Switch";

const ServiceDetail = ({ open, setOpenDetail, getServices, detail }) => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();
  // console.log(detail);

  const handleClose = () => {
    setOpenDetail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      service_name: title,
      price: price,
      category: detail.category,
    };

    axiosInstance
      .put(`/services/service/${detail._id}`, data, authentication)
      .then(() => {
        getServices();
        setIsEdit(false);
        setOpenDetail(false);
        clearInputs();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Data layanan berhasil diperbaharui!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const clearInputs = () => {
    setTitle("");
    setPrice("");
  };

  const deleteService = () => {
    setOpenDetail(false);
    Swal.fire({
      title: "Hapus Layanan?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/services/service/${detail._id}`, authentication)
          .then(() => {
            getServices();
            setIsEdit(false);
            setOpenDetail(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data Layanan telah terhapus!", "success");
        setTimeout(() => {
          navigate("/master/services");
        }, 2000);
      } else {
        setOpenDetail(true);
        Swal.fire("Oops!", "Terjadi kesalahan!", "error");
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/services/service/${detail._id}`, {}, authentication)
      .then(() => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status layanan berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getServices();
        setOpenDetail(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {detail && (
        <ModalCustom
          open={open}
          handleClose={() => setOpenDetail(false)}
          cardStyle={{ borderRadius: 2, width: 700, height: "auto" }}
        >
          {/* Header */}
          <Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="16px" fontWeight="600">
                  Detail Layanan
                </Typography>
                <Stack direction={"row"}>
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setOpenDetail(false)}
                  />
                </Stack>
              </Stack>
            </Box>
            <Divider />
          </Box>

          <Stack
            direction="row"
            justifyContent="right"
            paddingX={3}
            paddingY={1}
          >
            <Stack
              sx={{
                width: "fit-content",
                borderRadius: "10px",
                backgroundColor: `${
                  detail.status === true ? "#DEF8E9" : "#FBDFDF"
                }`,
              }}
              justifyContent="space-between"
              direction="row"
              px={1}
              py={1}
            >
              {detail.status === true ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#155C33",
                    fontWeight: "bold",
                    paddingTop: "1.5px",
                  }}
                >
                  Aktif
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#C41717",
                    fontWeight: "bold",
                    paddingTop: "1.5px",
                  }}
                >
                  Non-aktif
                </Typography>
              )}
              <Stack sx={{ py: "5px" }}>
                <StatusSwitch
                  status={!!detail.status}
                  changeStatus={changeStatus}
                />
              </Stack>
            </Stack>
          </Stack>

          {/* Body */}
          <form onSubmit={handleSubmit}>
            <Box flex="1">
              <Stack paddingX={3} paddingBottom={2}>
                {isEdit ? (
                  <Stack>
                    <Grid item xs={12}>
                      <CustomTextField
                        name="title"
                        label="Nama layanan"
                        placeholder={detail.service_name}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                      <CustomTextField
                        name="price"
                        label="Harga layanan"
                        placeholder={detail.price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack direction="row" paddingY={4} gap={2}>
                      <Grid
                        sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                      >
                        <Typography color={"#848786"}>Nama layanan</Typography>
                      </Grid>
                      <Grid sx={{ width: "70%" }}>
                        <Typography>{detail.service_name}</Typography>
                      </Grid>
                    </Stack>
                    <Stack direction="row" paddingBottom={4} gap={2}>
                      <Grid
                        sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                      >
                        <Typography color={"#848786"}>
                          Kategori layanan
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: "70%" }}>
                        <Typography>{detail.category}</Typography>
                      </Grid>
                    </Stack>
                    <Stack direction="row" paddingBottom={4} gap={2}>
                      <Grid
                        sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}
                      >
                        <Typography color={"#848786"}>Harga layanan</Typography>
                      </Grid>
                      <Grid sx={{ width: "70%" }}>
                        <Typography>
                          Rp {detail.price.toLocaleString(["ban", "id"])}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" justifyContent="right">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "16px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={() => (isEdit ? setIsEdit(false) : handleClose())}
                  >
                    {isEdit ? "Batal" : "Tutup"}
                  </Button>
                  {isEdit ? (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Simpan perubahan
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                  {isEdit ? null : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#ee3f11",
                        marginLeft: "16px",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => deleteService()}
                    >
                      Hapus
                    </Button>
                  )}
                </Stack>
              </Box>
            </Box>
          </form>
        </ModalCustom>
      )}
    </>
  );
};

export default ServiceDetail;
