import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Divider,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";
import WestIcon from "@mui/icons-material/West";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CKeditor from "./CKEditor";
import moment from "moment";
import CustomTextField from "../../../components/TextField";
import API_URL from "../../../utils/api/base";

const ArticleDetails = () => {
  const [details, setDetails] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedSource, setEditedSource] = useState("Air Limbah");
  const [editedHighlight, setEditedHighlight] = useState("");
  const [media, setMedia] = useState(null);
  const { articleId } = useParams();
  const categories = [
    {
      _id: "0",
      name: "Air Limbah",
    },
    {
      _id: "1",
      name: "Sampah",
    },
    {
      _id: "2",
      name: "Polusi",
    },
    {
      _id: "3",
      name: "Perubahan Iklim",
    },
    {
      _id: "4",
      name: "Daur Ulang",
    },
    {
      _id: "5",
      name: "Energi",
    },
    {
      _id: "6",
      name: "Pemanasan Global",
    },
  ];

  useEffect(() => {
    getArticleDetails();
    setEditorLoaded(true);
  }, []);

  const editSwitch = () => {
    setIsEdit(!isEdit);
    setIsDisabled(!isDisabled);
  };

  const getArticleDetails = () => {
    axiosInstance
      .get(`/blogs/${articleId}`, authentication)
      .then((res) => {
        setDetails(res.data);
        // console.log(res);
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", editedTitle);
    data.append("highlight", editedHighlight);
    data.append("source", editedSource);
    data.append("description", editedDescription);
    data.append("blog_picture", media);

    axiosInstance
      .put(`/blogs/${articleId}`, data, authentication)
      .then((res) => {
        // console.log(res);
        Swal.fire("Selesai!", "Artikel berhasil diperbaharui!", "success");
        editSwitch();
        getArticleDetails();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "Terjadi kesalahan!", "error");
      });
  };

  const deleteArticle = () => {
    Swal.fire({
      title: "Hapus artikel ini?",
      text: "Artikel tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(`/blogs/${articleId}`, authentication);
        Swal.fire("Selesai!", "Artikel berhasil dihapus!", "success");
        setTimeout(() => {
          navigate("/content/published-content");
        }, 2000);
      }
    });
  };

  // const hideArticle = () => {
  //   axiosInstance
  //     .patch(`/blogs/${articleId}`, {}, authentication)
  //     .then((res) => {
  //       // console.log(res);
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "success",
  //         title: "Artikel berhasil disembunyikan!",
  //         showConfirmButton: false,
  //         timer: 2000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "error",
  //         title: "Terjadi kesalahan!",
  //         showConfirmButton: false,
  //         timer: 2000,
  //       });
  //     });
  // };

  return (
    <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Button
          startIcon={<WestIcon />}
          sx={{
            textTransform: "capitalize",
            color: "#6D7688",
            fontWeight: "400",
          }}
          onClick={() => navigate("/content/published-content")}
        >
          Kembali
        </Button>
        <Stack direction="row">
          <Button
            startIcon={<EditIcon />}
            sx={{
              textTransform: "capitalize",
              fontWeight: "400",
              marginRight: 1,
              borderRadius: "20px",
            }}
            variant="contained"
            color="info"
            onClick={() => editSwitch()}
          >
            {isEdit ? "Batal" : "Edit Artikel"}
          </Button>
          {/* {user.role === "SuperAdmin" && (
            <Button
              startIcon={<DeleteIcon />}
              sx={{
                textTransform: "capitalize",
                fontWeight: "400",
                marginRight: 1,
                borderRadius: "20px",
              }}
              variant="contained"
              color="error"
              onClick={() => hideArticle()}
            >
              Sembunyikan
            </Button>
          )} */}
          <Button
            startIcon={<DeleteIcon />}
            sx={{
              textTransform: "capitalize",
              fontWeight: "400",
              borderRadius: "20px",
            }}
            variant="contained"
            color="error"
            onClick={(articleId) => deleteArticle(articleId)}
          >
            Hapus Artikel
          </Button>
        </Stack>
      </Stack>
      <Stack paddingX={3} paddingY={2} sx={{ fontFamily: "Murecho" }}>
        <Typography fontSize="24px" fontWeight="600" mb={1}>
          Detail Artikel
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack
          paddingX={3}
          paddingBottom={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
          justifyContent="center"
        >
          {isEdit ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <label htmlFor="profile_picture">
                <input
                  style={{ display: "none" }}
                  id="profile_picture"
                  onChange={(e) => setMedia(e.target.files[0])}
                  name="profile_picture"
                  type="file"
                />
                <Box
                  padding={3}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px dashed #6D7688",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                    color: "#6D7688",
                    marginBottom: "20px",
                    width: "540px",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#6D7688",
                      display: `${media != null ? "none" : null}`,
                    }}
                  >
                    Pilih gambar
                  </Typography>
                  {media !== null ? (
                    <Stack
                      sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={window.URL.createObjectURL(media)}
                        width={300}
                        height="100%"
                        alt="media"
                        style={{ borderRadius: ".3rem" }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#FFF",
                          mt: 2,
                          borderWidth: 2,
                        }}
                        onClick={() => setMedia(null)}
                      >
                        Ubah
                      </Button>
                    </Stack>
                  ) : null}
                </Box>
              </label>
            </Box>
          ) : (
            <img
              src={`${API_URL}${details.blog_picture}`}
              alt="Blog"
              crossOrigin="anonymous"
              style={{
                // backgroundColor: `#10838E`,
                width: "500px",
                height: "100%",
              }}
            />
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ width: "15%", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Judul</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} sx={{ width: "85%" }}>
              <CustomTextField
                name="title"
                placeholder={`${details.title}`}
                onChange={(e) => setEditedTitle(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2} sx={{ width: "85%" }}>
              <Typography fontWeight={600}>{details.title}</Typography>
            </Grid>
          )}
        </Stack>
        {isEdit === true ? null : (
          <Stack
            paddingX={3}
            paddingTop={2}
            sx={{ fontFamily: "Murecho" }}
            direction="row"
          >
            <Grid
              sx={{ width: "15%", borderRight: "2px solid #D3D3D3" }}
              color="#A1A1A1"
            >
              <Typography>Tanggal terbit</Typography>
            </Grid>
            <Grid ml={2} sx={{ width: "85%" }}>
              <Typography>{moment(details.createdAt).format("LL")}</Typography>
            </Grid>
          </Stack>
        )}
        {isEdit ? null : (
          <Stack
            paddingX={3}
            paddingTop={2}
            sx={{ fontFamily: "Murecho" }}
            direction="row"
          >
            <Grid
              sx={{ width: "15%", borderRight: "2px solid #D3D3D3" }}
              color="#A1A1A1"
            >
              <Typography>Author</Typography>
            </Grid>
            <Grid ml={2} sx={{ width: "85%" }}>
              <Typography>{details.author}</Typography>
            </Grid>
          </Stack>
        )}
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ width: "15%", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Kategori</Typography>
          </Grid>
          {isEdit === true ? (
            // <Grid ml={2} sx={{ width: "85%" }}>
            //   <CustomTextField
            //     name="source"
            //     placeholder={`${details.source}`}
            //     onChange={(e) => setEditedSource(e.target.value)}
            //   />
            // </Grid>
            <Grid ml={2} sx={{ width: "85%" }}>
              <CustomTextField
                name="category"
                label="Kategori"
                placeholder=""
                select
                value={editedSource}
              >
                {categories.map((ctg) => (
                  <MenuItem
                    key={ctg._id}
                    value={ctg.name}
                    onClick={() => setEditedSource(ctg.name)}
                  >
                    {ctg.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
          ) : (
            <Grid ml={2} sx={{ width: "85%" }}>
              <Typography>{details.source}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          paddingX={3}
          paddingTop={2}
          sx={{ fontFamily: "Murecho" }}
          direction="row"
        >
          <Grid
            sx={{ width: "15%", borderRight: "2px solid #D3D3D3" }}
            color="#A1A1A1"
          >
            <Typography>Highlight</Typography>
          </Grid>
          {isEdit === true ? (
            <Grid ml={2} sx={{ width: "85%" }}>
              <CustomTextField
                name="source"
                placeholder={`${details.highlight}`}
                multiline
                rows={4}
                onChange={(e) => setEditedHighlight(e.target.value)}
              />
            </Grid>
          ) : (
            <Grid ml={2} sx={{ width: "85%" }}>
              <Typography>{details.highlight}</Typography>
            </Grid>
          )}
        </Stack>
        <Stack
          // paddingX={2}
          // paddingY={2}
          // margin={3}
          padding={3}
          sx={{ fontFamily: "Murecho" }}
        >
          <CKeditor
            name="description"
            onChange={(data) => {
              setEditedDescription(data);
            }}
            value={details.description}
            editorLoaded={editorLoaded}
            isDisabled={isDisabled}
          />
        </Stack>
        {isEdit && (
          <Box>
            <Divider />
            <Box
              sx={{
                p: 3,
                background: "#fff",
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "20px",
                  }}
                  variant="outlined"
                  onClick={() => editSwitch()}
                >
                  Batal
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#0C78BC",
                    borderRadius: "20px",
                  }}
                  variant="contained"
                  type="submit"
                >
                  Perbaharui artikel
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default ArticleDetails;
