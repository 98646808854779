import React, { useState } from "react";
import { Typography, Stack, Paper, Divider } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from "@mui/icons-material/People";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleIcon from "@mui/icons-material/Article";
import WaterIcon from "@mui/icons-material/Water";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Chart from "react-apexcharts";
import { useEffect } from "react";
import axiosInstance from "utils/api/api";
import Card from "./Card";
import CardSummary from "./CardSummary";

const Dashboard = () => {
  const [dataSuccess, setDataSuccess] = useState([]);
  const [dataFail, setDataFail] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFail, setTotalFail] = useState(0);
  const [successCases, setSuccessCases] = useState(0);
  const [failedCases, setFailedCases] = useState(0);
  const [summary, setSummary] = useState(null);
  const [ringkasanPerusahaan, setRingkasanPerusahaan] = useState([]);

  const bar = {
    series: [
      {
        name: "Berhasil",
        data: dataSuccess,
      },
      {
        name: "Gagal",
        data: dataFail,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 300,
        stacked: false,
        fontFamily: "Murecho",
      },
      colors: ["#0E78BC", "#F48D4D"],
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: "25%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        title: {
          text: "Transaksi",
          style: {
            fontWeight: 500,
            fontSize: 12,
          },
        },
        labels: {
          style: {
            fontWeight: 400,
            fontSize: 12,
          },
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) + " transaksi";
          },
        },
      },
      title: {
        text: "",
      },
      xaxis: {
        categories: dataMonth,
        title: {
          text: "",
          style: {
            fontWeight: 500,
            fontSize: "24px",
          },
        },
        labels: {
          formatter: function (val) {
            return val;
          },
          style: {
            fontWeight: 500,
            fontSize: 12,
          },
        },
      },
    },
  };

  const getFinancialSummary = () => {
    axiosInstance
      .get("/overview/financial-summary")
      .then((res) => {
        // console.log(res.data.result[0]);
        setSummary(res?.data?.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompanySummary = () => {
    axiosInstance
      .get("/overview")
      .then((res) => {
        // console.log(res);
        setRingkasanPerusahaan(res?.data?.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculateCases = (data) => {
    const success = data.map((d) => {
      return d.success_count;
    });
    const failed = data.map((d) => {
      return d.cancel_count;
    });
    setSuccessCases(
      success.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0),
    );
    setFailedCases(
      failed.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0),
    );
  };

  const setChart = () => {
    let data_success = [];
    let data_fail = [];
    let data_month = [];
    let total_success = 0;
    let total_fail = 0;
    axiosInstance
      .get("/overview/barchart-transactions")
      .then((res) => {
        // console.log(res);
        res.data.result.map((d) => {
          data_success.push(d?.success_count);
          data_fail.push(d?.cancel_count);
          data_month.push(d?.month_name);
          total_success += d?.success_total_price;
          total_fail += d?.cancel_total_price;
        });
        setDataSuccess(data_success);
        setDataFail(data_fail);
        setDataMonth(data_month);
        setTotalSuccess(total_success);
        setTotalFail(total_fail);
        calculateCases(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFinancialSummary();
    getCompanySummary();
    setChart();
  }, []);

  const renderCardRingkasanKeuangan = () => (
    <>
      <Card
        label="Omset"
        value={summary?.omset?.toLocaleString(["ban", "id"])}
        icon={<PaidIcon fontSize="inherit" />}
        path={"/transactions/aggregator"}
      />
      <Card
        label="Fee Partner"
        value={summary?.fee_partner?.toLocaleString(["ban", "id"])}
        icon={<HandshakeIcon fontSize="inherit" />}
        path={"/transactions/aggregator"}
      />
      <Card
        label="Fee Flatform"
        value={summary?.fee_platform?.toLocaleString(["ban", "id"])}
        icon={<AccountBalanceIcon fontSize="inherit" />}
        path={"/transactions/aggregator"}
      />
    </>
  );

  const renderCardRingkasanPerusahaanA = () => (
    <>
      <Card
        label="IPLT"
        value={ringkasanPerusahaan?.iplts?.toLocaleString(["ban", "id"])}
        icon={<AccountBalanceIcon fontSize="inherit" />}
        path={"/master/iplt"}
      />
      <Card
        label="Partner"
        value={ringkasanPerusahaan?.partners?.toLocaleString(["ban", "id"])}
        icon={<HandshakeIcon fontSize="inherit" />}
        path={"/master/partners"}
      />
      <Card
        label="Pelanggan"
        value={ringkasanPerusahaan?.customers?.toLocaleString(["ban", "id"])}
        icon={<PeopleIcon fontSize="inherit" />}
        path={"/master/customers"}
      />
      <Card
        label="Layanan"
        value={ringkasanPerusahaan?.services?.toLocaleString(["ban", "id"])}
        icon={<MenuBookIcon fontSize="inherit" />}
        path={"/master/services"}
      />
    </>
  );

  const renderCardRingkasanPerusahaanB = () => (
    <>
      <Card
        label="Driver"
        value={ringkasanPerusahaan?.drivers?.toLocaleString(["ban", "id"])}
        icon={<PersonIcon fontSize="inherit" />}
        path={"/master/partners"}
      />
      <Card
        label="Armada"
        value={ringkasanPerusahaan?.fleets?.toLocaleString(["ban", "id"])}
        icon={<LocalShippingIcon fontSize="inherit" />}
        path={"/master/partners"}
      />
      <Card
        label="Artikel"
        value={ringkasanPerusahaan?.articles?.toLocaleString(["ban", "id"])}
        icon={<ArticleIcon fontSize="inherit" />}
        path={"/content/published-content"}
      />
      <Card
        label="Volume (&#x33A5;)"
        value={(ringkasanPerusahaan?.total_volume / 1000).toLocaleString([
          "ban",
          "id",
        ])}
        icon={<WaterIcon fontSize="inherit" />}
      />
    </>
  );

  const renderCardTransaction = () => (
    <>
      <CardSummary
        label="Total transaksi berhasil"
        value={totalSuccess?.toLocaleString(["ban", "id"])}
        rate={successCases}
        total={successCases + failedCases}
        type={true}
        icon={<CheckCircleIcon fontSize="inherit" />}
      />
      <CardSummary
        label="Total transaksi gagal"
        value={totalFail?.toLocaleString(["ban", "id"])}
        rate={failedCases}
        total={successCases + failedCases}
        type={false}
        icon={<CancelIcon fontSize="inherit" />}
      />
    </>
  );

  return (
    <>
      <Paper
        sx={{
          paddingX: 3,
          paddingY: 3,
          height: "auto",
          backgroundColor: "#F9FAFA",
        }}
      >
        <Typography variant="h4" fontWeight="500" color="#222" mb={1}>
          Dashboard
        </Typography>
        <Divider />
        <Typography variant="h6" fontWeight="500" mt={2} mb={2}>
          Ringkasan Keuangan
        </Typography>
        <Stack
          mb={6}
          direction="row"
          useFlexGap
          // flexWrap="wrap"
          justifyContent="space-between"
          gap={2}
        >
          {renderCardRingkasanKeuangan()}
        </Stack>
        <Typography variant="h6" fontWeight="500" mb={1}>
          Ringkasan Perusahaan
        </Typography>
        <Stack
          gap={2}
          mb={6}
          // useFlexGap
          // flexWrap={"wrap"}
        >
          <Stack direction="row" gap={2}>
            {renderCardRingkasanPerusahaanA()}
          </Stack>
          <Stack direction="row" gap={2}>
            {renderCardRingkasanPerusahaanB()}
          </Stack>
        </Stack>
        <Typography variant="h6" fontWeight="500">
          Transaksi
        </Typography>
        <Stack
          sx={{
            paddingY: 2,
            paddingX: 3,
            height: "min-content",
            backgroundColor: "white",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            gap={2}
            mb={3}
          >
            {renderCardTransaction()}
          </Stack>
          <Typography variant="h6" fontWeight="500">
            Riwayat Transaksi
          </Typography>
          <Chart
            series={bar.series}
            options={bar.options}
            type="bar"
            height="auto"
            width="100%"
          />
        </Stack>
      </Paper>
    </>
  );
};

export default Dashboard;
