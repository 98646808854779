import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/api/api";
import WestIcon from "@mui/icons-material/West";
import Swal from "sweetalert2";

const AggregatorDetails = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { id } = useParams();

  const getDetails = async () => {
    await axiosInstance
      .get(`/aggregator/data/${id}`, authentication)
      .then((res) => {
        // console.log(res.data?.result[0]);
        setDetails(res.data.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusUpdate = () => {
    Swal.fire({
      title: "Tandai selesai?",
      text: "Status data aggregator akan ditandai setelah diperbaharui",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Tandai",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(result);
        axiosInstance
          .patch(`/transactions/${details?._id}`, {}, authentication)
          .then(() => {
            getDetails();
            Swal.fire(
              "Selesai!",
              "Data agregator berhasil diperbaharui",
              "success",
            );
            // navigate(`/transactions/aggregator`);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // setSeeDriver(true);
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => navigate("/transactions/aggregator")}
          >
            Kembali
          </Button>
        </Stack>
        {user.role === "SuperAdmin" && (
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={2}
          >
            <Typography variant="h4" fontWeight="500">
              Detail Agregator
            </Typography>
            {details?.status_transfer_to_partner === false && (
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BD",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => statusUpdate()}
              >
                Tandai Selesai
              </Button>
            )}
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={1}
          spacing={1}
        >
          <Stack sx={{ width: "50%" }} spacing={3}>
            <Stack sx={{ width: "100%" }}>
              <Stack
                sx={{ borderRadius: "20px", background: "#FFF", padding: 2 }}
              >
                <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                  Data Partner
                </Typography>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Nama Perusahaan</Typography>
                  </Grid>
                  <Typography>{details?.company_name}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>No. HP</Typography>
                  </Grid>
                  <Typography>{details?.company_owner_contact}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Nama Pemilik</Typography>
                  </Grid>
                  <Typography textTransform={"capitalize"}>
                    {details?.company_owner_name}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ borderRadius: "20px", background: "#FFF", padding: 2 }}
            >
              <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                Data Bank
              </Typography>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Nama Akun</Typography>
                </Grid>
                <Typography textTransform={"capitalize"}>
                  {details?.company_bank_account_name}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Bank</Typography>
                </Grid>
                <Typography>{details?.company_bank_name}</Typography>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>No. Rekening</Typography>
                </Grid>
                <Typography>{details?.company_bank_number}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ width: "50%" }} paddingX={3}>
            <Stack sx={{ width: "100%" }}>
              <Stack
                sx={{ borderRadius: "20px", background: "#FFF", padding: 2 }}
              >
                <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                  Data Layanan
                </Typography>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>ID</Typography>
                  </Grid>
                  <Typography>{details?.reference_no}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Status</Typography>
                  </Grid>
                  {details?.status_transfer_to_partner === false ? (
                    <button
                      style={{
                        color: "#C41717",
                        fontFamily: "Murecho",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "6px 5% 6px 5%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#FBDFDF",
                      }}
                    >
                      <span>Belum Dicairkan</span>
                    </button>
                  ) : (
                    <button
                      style={{
                        color: "#155C33",
                        fontFamily: "Murecho",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "6px 5% 6px 5%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#DEF8E9",
                      }}
                    >
                      <span>Dicairkan</span>
                    </button>
                  )}
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Tanggal</Typography>
                  </Grid>
                  <Typography>{details?.date_transaction}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Layanan</Typography>
                  </Grid>
                  <Typography>{details?.service}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Biaya Layanan</Typography>
                  </Grid>
                  <Typography>
                    Rp {details?.service_price?.toLocaleString(["ban", "id"])}
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Biaya Partner</Typography>
                  </Grid>
                  <Typography>
                    Rp {details?.fee_partner?.toLocaleString(["ban", "id"])}
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                  <Grid
                    sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}
                  >
                    <Typography color={"#848786"}>Biaya Platform</Typography>
                  </Grid>
                  <Typography>
                    Rp {details?.fee_platform?.toLocaleString(["ban", "id"])}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default AggregatorDetails;
