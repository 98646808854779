import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axiosInstance from "../../utils/api/api";
import CreateAdmin from "./_components/CreateAdmin";
import CustomNoRowsOverlay from "../../components/NoRows";

const roboUrl = "https://robohash.org/";

const columns = [
  {
    field: "full_name",
    headerClassName: "header",
    headerName: "Admin",
    width: 600,
    flex: 1,
    renderCell: ({ value }) => (
      <>
        <Avatar
          src={`${roboUrl}${value}`}
          sx={{
            background: "linear-gradient(180deg, #0e78bc 0%, #000066 100%)",
            cursor: "pointer",
          }}
        />
        <Typography noWrap fontSize={14} ml={1} textTransform={"capitalize"}>
          {value}
        </Typography>
      </>
    ),
  },
  {
    field: "email",
    headerClassName: "header",
    headerName: "Alamat Email",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "phone_number",
    headerClassName: "header",
    headerName: "Nomor Telepon",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "role",
    headerClassName: "header",
    headerName: "Role",
    width: 100,
    flex: 1,
    renderCell: (params) =>
      params.value === "AdminPartner" ? (
        <button
          style={{
            color: "#C41717",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
          }}
        >
          <span>Partner Admin</span>
        </button>
      ) : params.value === "BlogAdmin" ? (
        <button
          style={{
            color: "#382A00",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FFE79E",
          }}
        >
          <span>Blog Admin</span>
        </button>
      ) : params.value === "SuperAdmin" ? (
        <button
          style={{
            color: "#2F80ED",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#ECF4FD",
          }}
        >
          <span>Super Admin</span>
        </button>
      ) : params.value === "AdminDisanitasi" ? (
        <button
          style={{
            color: "#FFF",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#0E78BD",
          }}
        >
          <span>Disanitasi Admin</span>
        </button>
      ) : (
        <button
          style={{
            color: "#155C33",
            fontFamily: "Murecho",
            fontWeight: "500",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
          }}
        >
          <span>IPLT Admin</span>
        </button>
      ),
  },
];

const AdminManagement = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getAdmin = () => {
    axiosInstance
      .get("/users/", authentication)
      .then((res) => {
        console.log(res);
        setAdmins(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <Box flex="1">
      <CreateAdmin
        open={modalOpen}
        setModalOpen={setModalOpen}
        getAdmin={getAdmin}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Pengelolaan Admin
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#0C78BD",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Tambah Admin
        </Button>
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={admins}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            isRowSelectable={() => false}
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            // onRowClick={() => navigate("/member/detail")}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default AdminManagement;
