import { Grid, Typography, Stack } from "@mui/material";
import React from "react";

function Header({ forgotPassword, passwordToken }) {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        mb={6}
        mt={2}
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <img
            src={
              "https://ik.imagekit.io/6tebrod5l/disanitasi/Logo_KiHiMY5Ba.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677907016043"
            }
            width={120}
            height="100%"
            alt="Disanitasi"
            srcSet=""
          />
        </div>
      </Grid>
      <Stack direction="row" justifyContent="center">
        <Typography
          mx={1}
          sx={{ color: "#222", fontSize: "24px", fontWeight: "500" }}
        >
          Selamat Datang
        </Typography>
      </Stack>
      <Grid item xs={12} sx={{ textAlign: "center" }} mb={4}>
        <Typography
          sx={{ color: "#292C33", fontSize: "16px", fontWeight: "400" }}
        >
          {forgotPassword
            ? "Mohon masukkan email terdaftar Anda"
            : passwordToken
            ? "Silahkan masukkan password baru Anda"
            : "Gunakan email dan password Anda untuk masuk"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;
