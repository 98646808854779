import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import axios from "axios";
import PROVINCES_URL from "../../../utils/api/provinces";
import API_URL from "../../../utils/api/base";
import Swal from "sweetalert2";

const EditFacility = ({ open, setModalOpen, getFacility, facility }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [treatmentCapacity, setTreatmentCapacity] = useState("");
  const [treatmentSystem, setTreatmentSystem] = useState("");
  const [media, setMedia] = useState(null);
  const [isPrevious, setIsPrevious] = useState(true);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [province, setProvince] = useState("Aceh");
  const [city, setCity] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const getProvince = async () => {
    await axios
      .get(PROVINCES_URL)
      .then((res) => {
        setProvinceList(res.data.provinsi);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetCity = (e) => {
    axios
      .get(
        `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${e}`,
      )
      .then((res) => {
        setCityList(res.data.kota_kabupaten);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetSubdistrict = (e) => {
    axios
      .get(
        `https://dev.farizdotid.com/api/daerahindonesia/kecamatan?id_kota=${e}`,
      )
      .then((res) => {
        setSubDistrictList(res.data.kecamatan);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const actionSetPostalCode = (e) => {
    axios
      .get(`https://kodepos.vercel.app/search/?q=${e}`)
      .then((res) => {
        setPostalCodeList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("iplt_name", name !== "" ? name : facility.iplt_name);
    formData.append("email", email !== "" ? email : facility.email);
    formData.append(
      "province",
      isPrevious !== true ? province : facility.address.province,
    );
    formData.append("city", isPrevious !== true ? city : facility.address.city);
    formData.append(
      "subdistrict",
      isPrevious !== true ? subDistrict : facility.address.subdistrict,
    );
    formData.append(
      "postal_code",
      isPrevious !== true ? postalCode : facility.address.postal_code,
    );
    formData.append(
      "detail_address",
      isPrevious !== true ? detailAddress : facility.address.detail_address,
    );
    formData.append("no_telpon", phone !== "" ? phone : facility.no_telpon);
    formData.append(
      "capasity",
      treatmentCapacity !== "" ? treatmentCapacity : facility.capasity,
    );
    formData.append(
      "system_processing",
      treatmentSystem !== "" ? treatmentSystem : facility.system_processing,
    );
    formData.append("iplt_picture", media);

    axiosInstance
      .put(`/iplts/iplt/${facility._id}`, formData, authentication)
      .then((res) => {
        // console.log(res);
        getFacility();
        setModalOpen(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Data IPLT berhasil diperbaharui!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProvince();
  }, []);

  return (
    <>
      {facility ? (
        <ModalCustom
          open={open}
          handleClose={() => setModalOpen(false)}
          cardStyle={{ borderRadius: 2, width: 1000, height: 650 }}
        >
          {/* Header */}
          <Box>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="16px" fontWeight="600">
                  Edit Data IPLT
                </Typography>
                <Grid item>
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setModalOpen(false)}
                  />
                </Grid>
              </Stack>
            </Box>
            <Divider />
          </Box>

          {/* Body */}
          <form onSubmit={handleSubmit}>
            <Box flex="1">
              <Stack paddingX={3} paddingY={2}>
                <Stack paddingTop={2} alignItems="center">
                  <label htmlFor="profile_picture">
                    <input
                      style={{ display: "none", cursor: "pointer" }}
                      id="profile_picture"
                      onChange={(e) => setMedia(e.target.files[0])}
                      name="profile_picture"
                      type="file"
                    />
                    <img
                      src={
                        media != null
                          ? window.URL.createObjectURL(media)
                          : `${API_URL}${facility.iplt_picture}`
                      }
                      alt="Profile"
                      style={{
                        height: "140px",
                        width: "140px",
                        cursor: "pointer",
                      }}
                      crossOrigin="anonymous"
                    />
                    {/* <Stack direction="row" justifyContent="right" paddingTop={2}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#0C78BC",
                      borderRadius: "20px",
                    }}
                    variant="contained"
                  >
                    Tambah Foto Profil
                  </Button>
                </Stack> */}
                  </label>
                </Stack>
                <Stack paddingTop={2} direction="row" gap={4}>
                  <Grid container xs={6} alignContent="flex-start">
                    <Typography variant="h6" py={1}>
                      Profil
                    </Typography>
                    <Grid item xs={12}>
                      <CustomTextField
                        name="name"
                        label="Nama instalasi"
                        placeholder={`${facility.iplt_name}`}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                      <CustomTextField
                        name="phone"
                        label="Telepon"
                        placeholder={`${facility.no_telpon}`}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={6} alignContent="flex-start">
                    <Typography variant="h6" py={1}>
                      Akun
                    </Typography>
                    <Grid item xs={12}>
                      <CustomTextField
                        name="email"
                        label="Alamat email"
                        placeholder={`${facility.email}`}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack paddingTop={1}>
                  <Grid container xs={6} alignContent="flex-start">
                    <Typography variant="h6" paddingTop={1}>
                      Alamat
                    </Typography>
                  </Grid>
                </Stack>
                <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPrevious}
                        onChange={() => setIsPrevious(!isPrevious)}
                      />
                    }
                    label={
                      <Typography color={"#848786"}>
                        Gunakan detail alamat sebelumnya
                      </Typography>
                    }
                  />
                </Stack>
                {isPrevious !== true && (
                  <Stack direction="row" gap={4}>
                    <Grid container xs={6} alignContent="flex-start">
                      <Grid item xs={12}>
                        <CustomTextField
                          name="province"
                          label="Pilih provinsi"
                          placeholder="Masukkan provinsi"
                          select
                          value={province}
                          onChange={(e) => setProvince(e.target.value)}
                        >
                          {provinceList.map((p) => (
                            <MenuItem
                              key={p.id}
                              value={p.nama}
                              onClick={() => actionSetCity(p.id)}
                            >
                              {p.nama}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <CustomTextField
                          name="city"
                          label="Pilih kota"
                          placeholder="Pilih kota"
                          select
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        >
                          {cityList.map((c) => (
                            <MenuItem
                              key={c.id}
                              value={c.nama}
                              onClick={() => actionSetSubdistrict(c.id)}
                            >
                              {c.nama}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <CustomTextField
                          name="kecamatan"
                          label="Pilih kecamatan"
                          placeholder="Masukkan kecamatan"
                          select
                          value={subDistrict}
                          onChange={(e) => setSubDistrict(e.target.value)}
                        >
                          {subDistrictList.map((s) => (
                            <MenuItem
                              key={s.id}
                              value={s.nama}
                              onClick={() => actionSetPostalCode(s.nama)}
                            >
                              {s.nama}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                    </Grid>
                    <Grid container xs={6} alignContent="flex-start">
                      <Grid item xs={12}>
                        <CustomTextField
                          name="postal_code"
                          label="Pilih kode pos"
                          placeholder="Masukkan kode pos"
                          select
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                        >
                          {postalCodeList.map((p) => (
                            <MenuItem
                              key={p.urban}
                              id={p.urban}
                              value={p.postalcode}
                            >
                              {p.postalcode} - {p.urban}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <CustomTextField
                          name="detail_address"
                          label="Detail lainnya"
                          placeholder={`${facility.address.detail_address}`}
                          onChange={(e) => setDetailAddress(e.target.value)}
                          multiline
                          rows={3}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                )}
                <Stack paddingTop={1}>
                  <Grid container xs={6} alignContent="flex-start">
                    <Typography variant="h6" py={1}>
                      Data Tambahan
                    </Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" gap={4}>
                  <Grid container xs={6} alignContent="flex-start">
                    <Grid item xs={12}>
                      <CustomTextField
                        name="capacity"
                        label="Kapasitas Pengolahan"
                        placeholder={`${facility.capasity}`}
                        onChange={(e) => setTreatmentCapacity(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={6} alignContent="flex-start">
                    <Grid item xs={12}>
                      <CustomTextField
                        name="treatment_system"
                        label="Sistem Pengolahan"
                        placeholder={`${facility.system_processing}`}
                        onChange={(e) => setTreatmentSystem(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" justifyContent="right">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "16px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={() => handleClose()}
                  >
                    Batal
                  </Button>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#0C78BC",
                      borderRadius: "20px",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Simpan
                  </Button>
                </Stack>
              </Box>
            </Box>
          </form>
        </ModalCustom>
      ) : (
        "Loading.."
      )}
    </>
  );
};

export default EditFacility;
