import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";

const adminCategory = [
  {
    value: "SuperAdmin",
    label: "Super Admin",
  },
  {
    value: "AdminDisanitasi",
    label: "Disanitasi Admin",
  },
  {
    value: "AdminPartner",
    label: "Partner Admin",
  },
  {
    value: "AdminIplt",
    label: "IPLT Admin",
  },
  {
    value: "BlogAdmin",
    label: "Blog Admin",
  },
];

const CreateAdmin = ({ open, setModalOpen, getAdmin }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [partners, setPartners] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPhone("");
    setRole("");
    setModalOpen(false);
    setPartners([]);
  };

  const getPartners = () => {
    axiosInstance
      .get("/partners/partner", authentication)
      .then((res) => {
        // console.log(res.data);
        setPartners(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFacilities = () => {
    axiosInstance
      .get("/iplts/iplt", authentication)
      .then((res) => {
        // console.log(res);
        setFacilities(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      full_name: name,
      email: email,
      phone_number: phone,
      password: password,
      role: role,
    };

    const adminPartner = {
      full_name: name,
      email: email,
      phone_number: phone,
      password: password,
      role: role,
      partner_id: partnerId,
    };

    const adminIplt = {
      full_name: name,
      email: email,
      phone_number: phone,
      password: password,
      role: role,
      iplt_id: facilityId,
    };

    axiosInstance
      .post(
        "/users/register",
        role === "AdminPartner"
          ? adminPartner
          : role === "AdminIplt"
          ? adminIplt
          : data,
        authentication,
      )
      .then((res) => {
        // console.log(res);
        getAdmin();
        setModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPartners();
    getFacilities();
  }, []);

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Admin Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="role"
                  label="Role"
                  placeholder="Please select an admin type"
                  select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  {adminCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              {role === "AdminPartner" && (
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="role"
                    label="Partner"
                    placeholder="Pilih partner"
                    select
                    value={partnerId}
                    onChange={(e) => setPartnerId(e.target.value)}
                  >
                    {partners.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name_company}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              )}
              {role === "AdminIplt" && (
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="role"
                    label="IPLT"
                    placeholder="Pilih IPLT"
                    select
                    value={facilityId}
                    onChange={(e) => setFacilityId(e.target.value)}
                  >
                    {facilities.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.iplt_name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              )}
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="name"
                  label="Nama lengkap"
                  placeholder="Masukkan nama lengkap Anda"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="email"
                  label="Email"
                  placeholder="Masukkan alamat email Anda"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Masukkan password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="phone_number"
                  label="Nomor telepon"
                  placeholder="Masukkan nomor telepon"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default CreateAdmin;
