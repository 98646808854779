import React from "react";
import AdminManagement from "../pages/Admin";
import AdminDetail from "../pages/Admin/Detail";

const root = "/users";

const admin = [
  {
    path: `${root}/`,
    component: <AdminManagement />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/:name`,
    component: <AdminDetail />,
    sidebar: true,
    navbar: true,
  },
];

export default admin;
