import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import axiosInstance from "utils/api/api";

const AddQuestion = ({ open, setModalOpen, getFAQ }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      question: question,
      answer: answer,
    };

    axiosInstance
      .post("/faqs", data, authentication)
      .then((res) => {
        // console.log(res);
        getFAQ();
        setModalOpen(false);
        Swal.fire("Selesai!", "Data pertanyaan telah terhapus!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Pertanyaan Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="question"
                  label="Pertanyaan"
                  placeholder="Masukkan pertanyaan"
                  onChange={(e) => setQuestion(e.target.value)}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="answer"
                  label="Jawaban"
                  placeholder="Masukkan jawaban"
                  onChange={(e) => setAnswer(e.target.value)}
                  multiline
                  rows={4}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddQuestion;
