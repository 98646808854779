import React, { useState } from "react";
import { Stack, Box, Typography, Tooltip, IconButton } from "@mui/material";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import FactoryIcon from "@mui/icons-material/Factory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ListIcon from "@mui/icons-material/List";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import HelpIcon from "@mui/icons-material/Help";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from "@mui/icons-material/Payments";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const superAdmin = [
  {
    sectionTitle: "DASHBOARD",
    items: [
      {
        icon: <DashboardIcon />,
        label: "Overview",
        navDestination: "/dashboard",
      },
    ],
  },
  {
    sectionTitle: "MASTER",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Daftar IPLT",
        navDestination: "/master/iplt",
      },
      {
        icon: <HandshakeIcon />,
        label: "Daftar Partner",
        navDestination: "/master/partners",
      },
      {
        icon: <ListIcon />,
        label: "Daftar Layanan",
        navDestination: "/master/services",
      },
      {
        icon: <Diversity1Icon />,
        label: "Daftar Pelanggan",
        navDestination: "/master/customers",
      },
    ],
  },
  {
    sectionTitle: "ADMINISTRATOR",
    items: [
      {
        icon: <AdminPanelSettingsIcon />,
        label: "Pengelolaan Admin",
        navDestination: "/users",
      },
    ],
  },
  {
    sectionTitle: "TRANSAKSI",
    items: [
      {
        icon: <ReceiptIcon />,
        label: "Daftar Transaksi",
        navDestination: "/transactions",
      },
      {
        icon: <AccountBalanceIcon />,
        label: "Daftar Bank",
        navDestination: "/transactions/banks",
      },
      {
        icon: <PaymentsIcon />,
        label: "Daftar Pembayaran",
        navDestination: "/transactions/payments",
      },
      {
        icon: <PointOfSaleIcon />,
        label: "Daftar Agregator",
        navDestination: "/transactions/aggregator",
      },
    ],
  },
  {
    sectionTitle: "BLOG",
    items: [
      {
        icon: <RssFeedIcon />,
        label: "Daftar Artikel",
        navDestination: "/content/published-content",
      },
      {
        icon: <KeyboardIcon />,
        label: "Tulis Artikel",
        navDestination: "/content/compose",
      },
    ],
  },
  {
    sectionTitle: "FAQ",
    items: [
      {
        icon: <HelpIcon />,
        label: "Daftar FAQ",
        navDestination: "/faq",
      },
    ],
  },
];

const adminDisanitasi = [
  {
    sectionTitle: "DASHBOARD",
    items: [
      {
        icon: <DashboardIcon />,
        label: "Overview",
        navDestination: "/dashboard",
      },
    ],
  },
  {
    sectionTitle: "MASTER",
    items: [
      // {
      //   icon: <FactoryIcon />,
      //   label: "Daftar IPLT",
      //   navDestination: "/master/iplt",
      // },
      {
        icon: <HandshakeIcon />,
        label: "Daftar Partner",
        navDestination: "/master/partners",
      },
      {
        icon: <Diversity1Icon />,
        label: "Daftar Pelanggan",
        navDestination: "/master/customers",
      },
    ],
  },
  {
    sectionTitle: "TRANSAKSI",
    items: [
      {
        icon: <AccountBalanceIcon />,
        label: "Daftar Bank",
        navDestination: "/transactions/banks",
      },
      {
        icon: <PaymentsIcon />,
        label: "Daftar Pembayaran",
        navDestination: "/transactions/payments",
      },
      {
        icon: <ReceiptIcon />,
        label: "Daftar Transaksi",
        navDestination: "/transactions",
      },
      // {
      //   icon: <PointOfSaleIcon />,
      //   label: "Laporan Penjualan",
      //   navDestination: "/transactions/aggregator",
      // },
    ],
  },
];

const adminIplt = [
  {
    sectionTitle: "MASTER",
    items: [
      {
        icon: <FactoryIcon />,
        label: "Daftar IPLT",
        navDestination: "/master/iplt",
      },
    ],
  },
];

const partner = [
  {
    sectionTitle: "MASTER",
    items: [
      // {
      //   icon: <FactoryIcon />,
      //   label: "Daftar IPLT",
      //   navDestination: "/master/iplt",
      // },
      {
        icon: <HandshakeIcon />,
        label: "Daftar Partner",
        navDestination: "/master/partners",
      },
      {
        icon: <Diversity1Icon />,
        label: "Daftar Pelanggan",
        navDestination: "/master/customers",
      },
    ],
  },
  {
    sectionTitle: "TRANSAKSI",
    items: [
      {
        icon: <ReceiptIcon />,
        label: "Daftar Transaksi",
        navDestination: "/transactions",
      },
    ],
  },
];

const BlogAdmin = [
  {
    sectionTitle: "BLOG",
    items: [
      {
        icon: <RssFeedIcon />,
        label: "Daftar Artikel",
        navDestination: "/content/published-content",
      },
      {
        icon: <KeyboardIcon />,
        label: "Tulis Artikel",
        navDestination: "/content/compose",
      },
    ],
  },
];

const SidebarSectionTitle = ({ children }) => (
  <Typography fontSize="14px" fontWeight="600" color="#FFF" marginLeft="16px">
    {children}
  </Typography>
);

const setJalan = (navigate, navDestination, setActive, label) => {
  navigate(navDestination);
  setActive(label);
};

const SidebarSectionItem = ({
  icon,
  label,
  navDestination,
  navigate,
  active,
  setActive,
  isExpand,
}) => (
  <Stack
    onClick={() => setJalan(navigate, navDestination, setActive, label)}
    direction="row"
    alignItems="center"
    spacing={1}
    paddingY={".1rem"}
    paddingLeft="8px"
    marginY={".1rem"}
    color={active === label ? "#222" : "#FFF"}
    sx={{
      ":hover": {
        backgroundColor: "#CEE4F2",
        color: "#222",
        cursor: "pointer",
        borderRadius: "3px",
      },
      backgroundColor: active === label ? "#CEE4F2" : "",
      borderRadius: "3px",
    }}
  >
    <Tooltip title={label} placement="right" arrow>
      <IconButton
        sx={{
          ":hover": {
            color: "#222",
            cursor: "pointer",
          },
          backgroundColor: "none",
          color: active === label ? "#222" : "#FFF",
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
    {isExpand === true && <Typography>{label}</Typography>}
  </Stack>
);

const Sidebar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Box
      width={isExpand ? "220px" : "55px"}
      overflow="scroll"
      paddingTop={10}
      paddingBottom={isExpand ? 1 : 2}
      paddingX={1}
      alignContent={"center"}
      sx={{
        backgroundColor: "#021826",
        borderBottomRightRadius: `${isExpand ? "none" : "35px"}`,
        height: `${isExpand ? "100vh" : "auto"}`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isExpand === true && "end"}
        spacing={1}
        paddingX="16px"
        borderRadius={1}
        marginTop={"1px"}
        color={"#FFF"}
      >
        {isExpand ? (
          <>
            <KeyboardDoubleArrowLeftIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setIsExpand(!isExpand)}
            />
          </>
        ) : (
          <>
            <KeyboardDoubleArrowRightIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setIsExpand(!isExpand)}
            />
          </>
        )}
      </Stack>
      {user.role === "SuperAdmin"
        ? superAdmin.map((section) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={section.sectionTitle}
              >
                {isExpand === true && (
                  <SidebarSectionTitle>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={item.icon}
                      label={item.label}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                      isExpand={isExpand}
                    />
                  );
                })}
              </Stack>
            );
          })
        : user.role === "AdminIplt"
        ? adminIplt.map((section) => {
            return (
              <Stack justifyContent="center" mb={2}>
                {isExpand === true && (
                  <SidebarSectionTitle>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={item.icon}
                      label={item.label}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                      isExpand={isExpand}
                    />
                  );
                })}
              </Stack>
            );
          })
        : user.role === "AdminPartner"
        ? partner.map((section) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={section.sectionTitle}
              >
                {isExpand === true && (
                  <SidebarSectionTitle>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={item.icon}
                      label={item.label}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                      isExpand={isExpand}
                    />
                  );
                })}
              </Stack>
            );
          })
        : user.role === "AdminDisanitasi"
        ? adminDisanitasi.map((section) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={section.sectionTitle}
              >
                {isExpand === true && (
                  <SidebarSectionTitle>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={item.icon}
                      label={item.label}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                      isExpand={isExpand}
                    />
                  );
                })}
              </Stack>
            );
          })
        : BlogAdmin.map((section) => {
            return (
              <Stack
                justifyContent="center"
                mb={isExpand === true && 2}
                key={section.sectionTitle}
              >
                {isExpand === true && (
                  <SidebarSectionTitle>
                    {section.sectionTitle}
                  </SidebarSectionTitle>
                )}
                {section.items.map((item) => {
                  return (
                    <SidebarSectionItem
                      icon={item.icon}
                      label={item.label}
                      navDestination={item.navDestination}
                      navigate={navigate}
                      setActive={setActive}
                      active={active}
                      key={item.label}
                      isExpand={isExpand}
                    />
                  );
                })}
              </Stack>
            );
          })}
    </Box>
  );
};

export default Sidebar;
