import React from "react";
import CustomerList from "../pages/Customers";
import CustomerDetails from "../pages/Customers/_components/CustomerDetails";
import TreatmentPlants from "../pages/Facilities";
import PartnerList from "../pages/Partners";
import Services from "../pages/Services";
import DetailFacility from "pages/Facilities/_components/DetailFacility";

const root = "/master";

const master = [
  {
    path: `${root}/iplt`,
    component: <TreatmentPlants />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/iplt/:id`,
    component: <DetailFacility />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/partners`,
    component: <PartnerList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/services`,
    component: <Services />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/customers`,
    component: <CustomerList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/customers/:id`,
    component: <CustomerDetails />,
    sidebar: true,
    navbar: true,
  },
];

export default master;
