import React from "react";
import ChangePassword from "pages/Password";

const root = "/forget-password";

const forgetPassword = [
  {
    path: `${root}`,
    component: <ChangePassword />,
    sidebar: false,
    navbar: false,
  },
];

export default forgetPassword;
