import React, { useEffect, useState } from "react";
import CKeditor from "./CKEditor";
import { Box, Button, Stack, Typography, Grid, MenuItem } from "@mui/material";
import CustomTextField from "../../../components/TextField";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Compose = () => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState(null);
  const [title, setTitle] = useState("");
  // const [source, setSource] = useState("");
  const [highlight, setHighlight] = useState("");
  const [category, setCategory] = useState("Air Limbah");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const categories = [
    {
      _id: "0",
      name: "Air Limbah",
    },
    {
      _id: "1",
      name: "Sampah",
    },
    {
      _id: "2",
      name: "Polusi",
    },
    {
      _id: "3",
      name: "Perubahan Iklim",
    },
    {
      _id: "4",
      name: "Daur Ulang",
    },
    {
      _id: "5",
      name: "Energi",
    },
    {
      _id: "6",
      name: "Pemanasan Global",
    },
  ];

  const handleClear = () => {
    Swal.fire({
      title: "Hapus semua elemen artikel??",
      text: "Elemen artikel tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        setTitle("");
        setDescription("");
        setMedia(null);
        setCategory("");
        setHighlight("");
        window.location.reload();
        Swal.fire("Selesai!", "Semua elemen artikel dihapus!", "success");
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", title);
    data.append("highlight", highlight);
    data.append("source", category);
    data.append("description", description);
    data.append("blog_picture", media);

    axiosInstance
      .post("/blogs/", data, authentication)
      .then((res) => {
        // console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Artikel berhasil dibuat!",
          showConfirmButton: true,
          timer: 2000,
        });
        setTitle("");
        setDescription("");
        setMedia(null);
        setCategory("Air Limbah");
        setHighlight("");
        setTimeout(() => {
          navigate("/content/published-content");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan. Silahkan periksa kembali.",
          showConfirmButton: true,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Buat Artikel
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Grid
            paddingX={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            {/* first half */}

            <Stack paddingTop={2}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                <Typography
                  mb={1}
                  fontSize="16px"
                  fontWeight="400"
                  color="#5A5A5A"
                >
                  Gambar Artikel
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <label htmlFor="profile_picture">
                  <input
                    style={{ display: "none" }}
                    id="profile_picture"
                    onChange={(e) => setMedia(e.target.files[0])}
                    name="profile_picture"
                    type="file"
                  />
                  <Box
                    padding={3}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dashed #6D7688",
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      color: "#6D7688",
                      marginBottom: "20px",
                      width: "500px",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {/* <AddIcon
                    sx={{
                      color: "#6D7688",
                      display: `${media != null ? "none" : null}`,
                    }}
                  /> */}
                    <Typography
                      sx={{
                        color: "#6D7688",
                        display: `${media != null ? "none" : null}`,
                      }}
                    >
                      Pilih Gambar
                    </Typography>
                    {media !== null ? (
                      <Stack
                        sx={{
                          padding: 1,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={window.URL.createObjectURL(media)}
                          width={300}
                          height="100%"
                          alt="media"
                          style={{ borderRadius: ".3rem" }}
                        />
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFF",
                            mt: 2,
                            borderWidth: 2,
                          }}
                          onClick={() => setMedia(null)}
                        >
                          Ubah
                        </Button>
                        {/* <Typography fontSize="16px" fontWeight="500" mb={2}>
                          {media.name}
                        </Typography> */}
                      </Stack>
                    ) : null}
                  </Box>
                </label>
              </Box>
            </Stack>
          </Grid>

          {/* second half */}
          <Grid
            paddingRight={3}
            paddingY={2}
            sx={{ width: "50%", minHeight: "300px" }}
          >
            <Grid item xs={12} paddingTop={2}>
              <CustomTextField
                name="title"
                label="Judul"
                placeholder="Masukkan judul artikel"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} paddingY={2}>
              <CustomTextField
                name="highlight"
                label="Highlight"
                placeholder="Masukkan highlight singkat"
                onChange={(e) => setHighlight(e.target.value)}
                multiline
                rows={4}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <CustomTextField
                name="source"
                label="Sumber"
                placeholder="Masukkan sumber artikel ini"
                onChange={(e) => setSource(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <CustomTextField
                name="category"
                label="Kategori"
                placeholder=""
                select
                value={category}
              >
                {categories.map((ctg) => (
                  <MenuItem
                    key={ctg._id}
                    value={ctg.name}
                    onClick={() => setCategory(ctg.name)}
                  >
                    {ctg.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>

            {/* <Grid item xs={12} paddingY={2}>
              <CustomTextField
                name="description"
                label="Description"
                placeholder="Insert a short description about this article"
                multiline
                rows={4}
              />
            </Grid> */}
          </Grid>
        </Stack>
        <Stack paddingX={3}>
          <Grid>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                // textAlign: "center",
              }}
            >
              <Typography
                mb={1}
                fontSize="16px"
                fontWeight="400"
                color="#5A5A5A"
              >
                Konten Artikel
              </Typography>
            </Box>
            <CKeditor
              name="description"
              onChange={(data) => {
                setDescription(data);
              }}
              editorLoaded={editorLoaded}
            />
          </Grid>
        </Stack>
        <Box>
          {/* <Divider /> */}
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClear()}
              >
                Hapus semua
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                variant="contained"
                type="submit"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Compose;
