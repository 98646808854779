import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const RejectTransaction = ({
  modalOpen,
  setModalOpen,
  id,
  isAction,
  setIsAction,
  addCustomer,
}) => {
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setIsAction("");
    setRemark("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const rejectData = {
      remark: remark,
      status: false,
    };

    const acceptData = {
      status: true,
      remark: "Pesanan diproses.",
    };

    axiosInstance
      .put(
        `/transactions/action/${id}`,
        isAction === "Accept" ? acceptData : rejectData,
        authentication,
      )
      .then((res) => {
        // console.log(res);
        setModalOpen(false);
        isAction === "Accept" && addCustomer();
        isAction === "Accept"
          ? Swal.fire("Selesai!", "Transaksi diterima", "success")
          : Swal.fire("Selesai!", "Transaksi dibatalkan", "success");
        setTimeout(() => {
          navigate("/transactions");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Konfirmasi
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                {isAction === "Accept" ? (
                  <Typography>
                    Anda akan menerima pesanan ini. Lanjutkan ?{" "}
                  </Typography>
                ) : (
                  <CustomTextField
                    name="remark"
                    label="Masukkan alasan transaksi ini dibatalkan:"
                    placeholder="Keterangan"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                )}
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Tutup
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                {isAction === "Accept" ? "Terima" : "Tolak"}
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default RejectTransaction;
