import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";

const AddBank = ({
  open,
  setModalOpen,
  getBanks,
  getPartnerBanks,
  value,
  partners,
}) => {
  const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const bankList = [
    {
      _id: "S01",
      name: "BCA",
    },
    {
      _id: "S02",
      name: "BRI",
    },
    {
      _id: "S03",
      name: "BNI",
    },
    {
      _id: "S04",
      name: "MANDIRI",
    },
  ];

  const handleClose = () => {
    setBank("");
    setName("");
    setAccountNumber("");
    setSelectedPartner("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      bank_name: bank,
      no_rek: accountNumber,
    };

    const partnerData = {
      name: name,
      bank_name: bank,
      no_rek: accountNumber,
      partner_id: selectedPartner,
    };

    axiosInstance
      .post("/banks", value === 0 ? data : partnerData, authentication)
      .then(() => {
        value === 0 ? getBanks() : getPartnerBanks();
        setModalOpen(false);
        Swal.fire("Selesai!", "Data Bank berhasil ditambahkan!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              {value === 0
                ? "Tambah Rekening Pelanggan"
                : "Tambah Rekening Partner"}
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Grid item xs={12} paddingBottom={2}>
              <CustomTextField
                name="name"
                label="Pemilik rekening"
                placeholder="Masukkan nama pemilik rekening"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            {value !== 0 && (
              <Grid item xs={12} paddingBottom={2}>
                <CustomTextField
                  name="partner"
                  label="Pilih partner"
                  placeholder="Pilih Partner"
                  select
                  value={selectedPartner}
                >
                  {partners.map((p) => (
                    <MenuItem
                      key={p._id}
                      value={`${p._id}`}
                      onClick={() => setSelectedPartner(`${p._id}`)}
                    >
                      {p.name_company}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
            )}
            <Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="title"
                  label="Nama bank"
                  placeholder="Masukkan nama bank"
                  select
                  value={bank}
                >
                  {bankList.map((s) => (
                    <MenuItem
                      key={s._id}
                      value={s.name}
                      onClick={() => setBank(s.name)}
                    >
                      {s.name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                <CustomTextField
                  name="account_number"
                  label="Nomor rekening"
                  placeholder="Masukkan nomor rekening"
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddBank;
