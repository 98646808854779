import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import moment from "moment";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "createdAt",
    headerClassName: "header",
    headerName: "Tanggal",
    width: 120,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>{moment(value).format("LL")}</span>
    ),
  },
  {
    field: "service",
    headerClassName: "header",
    headerName: "Nama Layanan",
    width: 250,
    // flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6, textTransform: "capitalize" }}>
        {value?.service_name}
      </span>
    ),
  },
  {
    field: "total_price",
    headerClassName: "header",
    headerName: "Biaya Layanan (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>
        {parseInt(value).toLocaleString(["ban", "id"])}
      </span>
    ),
  },
  {
    field: "status_messsage",
    headerClassName: "header",
    headerName: "Status",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) =>
      value === "Batal" ? (
        <button
          style={{
            color: "#C41717",
            fontWeight: "500",
            fontFamily: "Murecho",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
            marginLeft: "6px",
          }}
        >
          <span>{value}</span>
        </button>
      ) : value === "Pending" ? (
        <button
          style={{
            color: "#382A00",
            fontWeight: "500",
            fontFamily: "Murecho",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FFE79E",
            marginLeft: "6px",
          }}
        >
          <span>{value}</span>
        </button>
      ) : (
        <button
          style={{
            color: "#155C33",
            fontWeight: "500",
            fontFamily: "Murecho",
            fontSize: "12px",
            padding: "6px 5% 6px 5%",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
            marginLeft: "6px",
          }}
        >
          <span>{value}</span>
        </button>
      ),
  },
  {
    field: "buyer_data",
    headerClassName: "header",
    headerName: "Nama Pelanggan",
    width: 200,
    // flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6, textTransform: "capitalize" }}>
        {value.name}
      </span>
    ),
  },
  {
    field: "remark",
    headerClassName: "header",
    headerName: "Remark",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <span style={{ paddingLeft: 6 }}>{value ? value : "-"}</span>
    ),
  },
];

const TransactionList = () => {
  const [pageSize, setPageSize] = useState(10);
  // const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getTransactions = () => {
    axiosInstance
      .get("/transactions/", authentication)
      .then((res) => {
        user.role === "AdminPartner"
          ? setTransactions(
              res.data.filter((r) => r.partner_id === user.partner_id),
            )
          : setTransactions(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar Transaksi
        </Typography>
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={transactions}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(row) => navigate(`/transactions/list/${row.id}`)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default TransactionList;
