import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import axiosInstance from "utils/api/api";
import CustomTextField from "../../../components/TextField";

const QuestionDetails = ({ isOpen, setIsOpen, getFAQ, detail }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsEdit(false);
  };

  const handleSubmit = () => {
    const data = {
      question: question,
      answer: answer,
    };

    axiosInstance
      .put(`/faqs/${detail._id}`, data, authentication)
      .then(() => {
        getFAQ();
        setIsOpen(false);
        setIsEdit(false);
        Swal.fire(
          "Selesai!",
          "Data pertanyaan berhasil diperbaharui!",
          "success",
        );
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "Terjadi kesalahan!", "error");
      });
  };

  const deleteQuestion = () => {
    setIsOpen(false);
    Swal.fire({
      title: "Hapus pertanyaan ini?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/faqs/${detail._id}`, authentication)
          .then(() => {
            getFAQ();
            // setIsEdit(false);
            setIsOpen(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data pertanyaan telah terhapus!", "success");
      } else {
        setIsOpen(true);
      }
    });
  };

  return (
    <ModalCustom
      open={isOpen}
      handleClose={() => setIsOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Detail FAQ
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setIsOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Stack direction="row" paddingY={2} gap={2}>
                <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Pertanyaan</Typography>
                </Grid>
                <Grid sx={{ width: "70%" }}>
                  {isEdit ? (
                    <Grid item xs={12} paddingTop={2}>
                      <CustomTextField
                        name="question"
                        placeholder={detail.question}
                        onChange={(e) => setQuestion(e.target.value)}
                      />
                    </Grid>
                  ) : (
                    <Typography fontWeight={600}>{detail.question}</Typography>
                  )}
                </Grid>
              </Stack>
              <Stack direction="row" paddingBottom={2} gap={2}>
                <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Jawaban</Typography>
                </Grid>
                <Grid sx={{ width: "70%" }}>
                  {isEdit ? (
                    <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                      <CustomTextField
                        name="answer"
                        placeholder={detail.answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        multiline
                        rows={4}
                      />
                    </Grid>
                  ) : (
                    <Typography>{detail.answer}</Typography>
                  )}
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Tutup
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#ee3f11",
                  borderRadius: "20px",
                  marginRight: "16px",
                  color: "#fff",
                }}
                variant="outlined"
                onClick={() => deleteQuestion()}
              >
                Hapus
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => (isEdit ? handleSubmit() : setIsEdit(true))}
              >
                {isEdit ? "Simpan" : "Edit"}
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default QuestionDetails;
