export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isValidPhoneNumber(phone) {
  return /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/.test(
    phone,
  );
}

export function isNumber(number) {
  return /^\d+$/.test(number);
}
