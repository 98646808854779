import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/api/api";
import StatusSwitch from "../../../components/Switch";
import DriverList from "../../../pages/Driver";
import FleetManagement from "../../../pages/Fleet";
import EditPartner from "./EditPartner";
import WestIcon from "@mui/icons-material/West";
import Swal from "sweetalert2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const PartnerDetails = () => {
  // const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [partner, setPartner] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [value, setValue] = useState(0);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { id } = useParams();

  const getPartner = async () => {
    await axiosInstance
      .get(`/partners/partner/${id}`, authentication)
      .then((res) => {
        // console.log(res.data[0]);
        setPartner(res.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDrivers = () => {
    axiosInstance
      .get(`/driver/${id}`, authentication)
      .then((res) => {
        // console.log(res);
        setDrivers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFleets = () => {
    axiosInstance
      .get(`/fleets/${id}`, authentication)
      .then((res) => {
        // console.log(res);
        setFleets(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPartner();
    getDrivers();
    getFleets();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/partners/partner/${id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status Partner berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getPartner();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const removePartner = () => {
    Swal.fire({
      title: "Hapus partner?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/partners/partner/${id}`, authentication)
          .then((res) => {
            // console.log(res.data);
            navigate("/master/partners");
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data Partner telah terhapus!", "success");
        setTimeout(() => {
          navigate("/master/partners");
        }, 2000);
      }
    });
  };

  return (
    <>
      <EditPartner
        open={modalOpen}
        setModalOpen={setModalOpen}
        getPartner={getPartner}
        partner={partner}
      />
      <Box flex="1">
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => navigate("/master/partners")}
          >
            Kembali
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Detail Partner
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            {user.role === "SuperAdmin" && (
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#ee3f11",
                  borderRadius: "20px",
                  marginRight: 1,
                }}
                variant="contained"
                onClick={(p) => removePartner()}
              >
                Hapus Partner
              </Button>
            )}

            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#0C78BD",
                borderRadius: "20px",
              }}
              variant="contained"
              onClick={() => setModalOpen(true)}
            >
              Edit Partner
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="right"
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingX={3}
          paddingY={1}
        >
          <Stack
            sx={{
              width: "fit-content",
              borderRadius: "10px",
              backgroundColor: `${partner.status ? "#DEF8E9" : "#FBDFDF"}`,
            }}
            justifyContent="space-between"
            direction="row"
            px={1}
            py={1}
          >
            {partner.status ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#155C33",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#C41717",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Non-aktif
              </Typography>
            )}

            <Stack sx={{ py: "5px" }}>
              <StatusSwitch
                status={!!partner.status}
                changeStatus={changeStatus}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
          gap={2}
        >
          <Stack sx={{ width: "50%" }} gap={2}>
            <Stack
              sx={{ borderRadius: "20px", background: "#FFF", padding: 2 }}
            >
              <Typography variant="h5" fontWeight="500" sx={{ mb: 2 }}>
                Profil
              </Typography>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Nama Perusahaan</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{partner.name_company}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>No. HP</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{partner.no_telpon}</Typography>
                </Grid>
              </Stack>
            </Stack>
            <Stack
              sx={{ borderRadius: "20px", background: "#FFF", padding: 2 }}
            >
              <Typography variant="h5" fontWeight="500" sx={{ my: 1 }}>
                Data Pemilik
              </Typography>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Nama</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{partner.owner_name}</Typography>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "20px",
              background: "#FFF",
              padding: 2,
              height: "fit-content",
            }}
          >
            <Typography variant="h5" fontWeight="500" sx={{ mb: 2 }}>
              Alamat
            </Typography>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kota</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{partner?.address?.city}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kecamatan</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{partner?.address?.subdistrict}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kode Pos</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{partner?.address?.postal_code}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Detail lainnya</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{partner?.address?.detail_address}</Typography>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ height: "fit-content", overflow: "scroll" }}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              marginLeft={3}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Driver"
                  sx={{ textTransform: "capitalize" }}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Kendaraan"
                  sx={{ textTransform: "capitalize" }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Stack>
          <TabPanel value={value} index={0}>
            <DriverList
              partnerId={partner._id}
              drivers={drivers}
              getDrivers={getDrivers}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FleetManagement
              partnerId={partner._id}
              fleets={fleets}
              getFleets={getFleets}
            />
          </TabPanel>
        </Stack>
      </Box>
    </>
  );
};

export default PartnerDetails;
