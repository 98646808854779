import React from "react";
import { Box, Stack, Typography, Grid } from "@mui/material";
import moment from "moment";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("admin_data"));

  const today = moment().format("LL");

  return (
    <Box flex="1">
      <Stack
        // direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography fontSize="24px" fontWeight="600">
          Dashboard Disanitasi
        </Typography>
        <Typography fontSize="14px" color="#B1B1B1" fontWeight="600">
          {today}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "24px",
            paddingLeft: "20px",
            backgroundColor: "#FFF",
            paddingBottom: "100px",
          }}
        >
          <Typography variant="h5" color="#0C78BC" fontWeight="600" mb={1}>
            Hai, {user.full_name}!
          </Typography>
          <Typography variant="h6" color="#0C78BC" fontWeight="400">
            Selamat datang di <strong>Dashboard Disanitasi</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
