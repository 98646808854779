import React from "react";
import ArticleDetails from "../pages/Feeds/_components/ArticleDetails";
import Feeds from "pages/Feeds";
import Compose from "pages/Feeds/_components/Compose";

const root = "/content";

const feeds = [
  {
    path: `${root}/published-content`,
    component: <Feeds />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/compose`,
    component: <Compose />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/published-content/:articleId`,
    component: <ArticleDetails />,
    sidebar: true,
    navbar: true,
  },
];

export default feeds;
