import React from "react";
import { Route, Routes } from "react-router-dom";
import { Stack } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import routes from "../index";
import Navbar from "../../components/Navbar";
import Footer from "components/Footer";

function Main() {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        {/* Navbar ----------------------------- */}
        <Routes>
          {routes.map((route) => {
            if (route.navbar)
              return <Route path={route.path} element={<Navbar />} />;
          })}
        </Routes>

        {/* ---------------------------------- */}
        <Stack
          direction="row"
          sx={{
            top: "56px",
            height: "fit-content",
          }}
        >
          {/* Render Sidebar */}
          <Stack sx={{ minHeight: "auto" }}>
            <Routes>
              {routes.map((route) => {
                if (route.sidebar)
                  return <Route path={route.path} element={<Sidebar />} />;
              })}
            </Routes>
          </Stack>

          {/* Render Page */}
          <Stack
            sx={{
              width: "100%",
              height: "730px",
              overflow: "scroll",
              paddingTop: "56px",
            }}
          >
            <Routes>
              {routes.map((route) => {
                return <Route path={route.path} element={route.component} />;
              })}
            </Routes>
          </Stack>
        </Stack>
      </div>
      <Stack position={"relative"}>
        <Footer />
      </Stack>
    </div>
  );
}

export default Main;
