import React from "react";
import Home from "../pages/Home";

const root = "/";

const home = [
  {
    path: `${root}/`,
    component: <Home />,
    navbar: true,
    sidebar: true,
  },
];

export default home;
