import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import API_URL from "../../../utils/api/base";
import Swal from "sweetalert2";
import StatusSwitch from "../../../components/Switch";

const DetailDriver = ({
  seeDriver,
  setSeeDriver,
  getDrivers,
  driverData,
  partnerId,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const status = true;
  const [isEdit, setIsEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [media, setMedia] = useState(null);

  // console.log(driverData._id);

  const handleClose = () => {
    setSeeDriver(false);
    setEdit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("full_name", name);
    formData.append("email", email);
    formData.append("no_hp", phone);
    formData.append("partner_id", partnerId);
    formData.append("driver_picture", media);
    formData.append("status", status);

    axiosInstance
      .put(`/driver/${driverData._id}`, formData, authentication)
      .then((res) => {
        // console.log(res);
        Swal.fire("Selesai!", "Driver berhasil diperbaharui!", "success");
        getDrivers();
        setSeeDriver(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "Terjadi kesalahan!", "error");
      });
  };

  const deleteDriver = () => {
    setSeeDriver(false);
    Swal.fire({
      title: "Hapus Driver?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/driver/${driverData._id}`, authentication)
          .then(() => {
            getDrivers();
            setSeeDriver(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data driver telah terhapus!", "success");
      } else {
        setSeeDriver(true);
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/driver/${driverData._id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status driver berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getDrivers();
        setSeeDriver(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "Terjadi kesalahan!", "error");
      });
  };

  return (
    <>
      <ModalCustom
        open={seeDriver}
        handleClose={() => setSeeDriver(false)}
        cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="16px" fontWeight="600">
                Detail Driver
              </Typography>
              <Grid item>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Stack>
          </Box>
          <Divider />
        </Box>

        {isEdit ? null : (
          <Stack
            direction="row"
            justifyContent="right"
            paddingX={3}
            paddingY={1}
          >
            <Stack
              sx={{
                width: "fit-content",
                borderRadius: "10px",
                backgroundColor: `${driverData.status ? "#DEF8E9" : "#FBDFDF"}`,
              }}
              justifyContent="space-between"
              direction="row"
              px={1}
              py={1}
            >
              {driverData.status ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#155C33",
                    fontWeight: "bold",
                    paddingTop: "1.5px",
                  }}
                >
                  Aktif
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#C41717",
                    fontWeight: "bold",
                    paddingTop: "1.5px",
                  }}
                >
                  Non-aktif
                </Typography>
              )}

              <Stack sx={{ py: "5px" }}>
                <StatusSwitch
                  status={!!driverData.status}
                  changeStatus={changeStatus}
                />
              </Stack>
            </Stack>
          </Stack>
        )}

        {isEdit ? (
          <form onSubmit={handleSubmit}>
            <Box flex="1">
              <Stack paddingTop={2} alignItems="center">
                <label htmlFor="driver_picture">
                  <input
                    style={{ display: "none", cursor: "pointer" }}
                    id="driver_picture"
                    onChange={(e) => setMedia(e.target.files[0])}
                    name="driver_picture"
                    type="file"
                  />
                  <img
                    src={
                      media != null
                        ? window.URL.createObjectURL(media)
                        : `${API_URL}${driverData.driver_picture}`
                    }
                    alt="Profile"
                    style={{
                      height: "140px",
                      width: "140px",
                      cursor: "pointer",
                    }}
                    crossOrigin="anonymous"
                  />
                </label>
              </Stack>
              <Stack paddingX={3} paddingY={2}>
                <Stack>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="name"
                      label="Nama lengkap"
                      placeholder={`${driverData.full_name}`}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} paddingTop={2}>
                    <CustomTextField
                      name="email"
                      label="Alamat Email"
                      placeholder={`${driverData.email}`}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} paddingTop={2}>
                    <CustomTextField
                      name="phone"
                      label="Nomor WhatsApp"
                      type="text"
                      placeholder={`${driverData.no_hp}`}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" justifyContent="right">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "16px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      isEdit ? setIsEdit(!isEdit) : handleClose()
                    }
                  >
                    {isEdit ? "Batal" : "Tutup"}
                  </Button>
                  {/* {isEdit ? null : (
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#ee3f11",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => deleteDriver()}
              >
                Hapus
              </Button>
            )} */}
                  {isEdit ? (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Simpan Perubahan
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Stack>
              </Box>
            </Box>
          </form>
        ) : (
          <>
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack>
                <Stack paddingTop={4} alignItems="center">
                  <img
                    src={`${API_URL}${driverData.driver_picture}`}
                    alt="Profile"
                    crossOrigin="anonymous"
                    style={{
                      height: "140px",
                      width: "140px",
                      borderRadius: "100px",
                    }}
                  />
                </Stack>
                <Stack direction="row" paddingY={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nama lengkap</Typography>
                  </Grid>
                  <Grid sx={{ width: "70%" }}>
                    <Typography>{driverData.full_name}</Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" paddingBottom={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Email</Typography>
                  </Grid>
                  <Grid sx={{ width: "70%" }}>
                    <Typography>{driverData.email}</Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" paddingBottom={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nomor HP</Typography>
                  </Grid>
                  <Grid sx={{ width: "70%" }}>
                    <Typography>{driverData.no_hp}</Typography>
                  </Grid>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" justifyContent="right">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginRight: "16px",
                      borderRadius: "20px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      isEdit ? setIsEdit(!isEdit) : handleClose()
                    }
                  >
                    {isEdit ? "Batal" : "Tutup"}
                  </Button>
                  {isEdit ? null : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#ee3f11",
                        borderRadius: "20px",
                        marginRight: "16px",
                      }}
                      variant="contained"
                      onClick={() => deleteDriver()}
                    >
                      Hapus
                    </Button>
                  )}
                  {isEdit ? (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Simpan Perubahan
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#0C78BC",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Stack>
              </Box>
            </Box>
          </>
        )}
      </ModalCustom>
    </>
  );
};

export default DetailDriver;
