import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Tab, Tabs } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import CustomNoRowsOverlay from "../../components/NoRows";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  {
    field: "reference_no",
    headerClassName: "header",
    headerName: "ID",
    width: 100,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "date_transaction",
    headerClassName: "header",
    headerName: "Tanggal Transaksi",
    width: 120,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "service",
    headerClassName: "header",
    headerName: "Nama Layanan",
    width: 200,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "service_price",
    headerClassName: "header",
    headerName: "Biaya Layanan (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value.toLocaleString(["ban", "id"])}
      </Typography>
    ),
  },
  {
    field: "fee_partner",
    headerClassName: "header",
    headerName: "Biaya Partner (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value.toLocaleString(["ban", "id"])}
      </Typography>
    ),
  },
  {
    field: "fee_platform",
    headerClassName: "header",
    headerName: "Biaya Platform (Rp)",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value.toLocaleString(["ban", "id"])}
      </Typography>
    ),
  },
  {
    field: "company_name",
    headerClassName: "header",
    headerName: "Nama Perusahaan",
    width: 150,
    // flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "company_bank",
    headerClassName: "header",
    headerName: "Akun Bank",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14} textTransform={"capitalize"}>
        {value}
      </Typography>
    ),
  },
  // {
  //   field: "",
  //   headerClassName: "header",
  //   headerName: "Status",
  //   width: 150,
  //   flex: 1,
  //   renderCell: ({ value }) => (
  //     <Typography noWrap fontSize={14} textTransform={"capitalize"}>
  //       Belum Dicairkan
  //     </Typography>
  //   ),
  // },
];

const ReportPage = () => {
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = useState(0);
  const [aggregatorData, setAggregatorData] = useState([]);
  const [disbursed, setDisbursed] = useState([]);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const getAggregator = () => {
    axiosInstance
      .get("/aggregator/funds-awaiting-disbursement", authentication)
      .then((res) => {
        // console.log(res.data);
        setAggregatorData(res?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDisbursedData = () => {
    axiosInstance
      .get("/aggregator/funds-disbursed", authentication)
      .then((res) => {
        // console.log(res.data);
        setDisbursed(res?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAggregator();
    getDisbursedData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flex="1">
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Laporan Agregator
        </Typography>
      </Stack>
      {/* <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={aggregatorData}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
            }}
            onRowClick={(row) => navigate(`/transactions/aggregator/${row.id}`)}
          />
        </Box>
      </Stack> */}
      <Box sx={{ paddingX: 3 }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Belum Dicairkan"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="Dicairkan"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
              }}
            >
              <DataGrid
                rows={aggregatorData}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows.reference_no}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) =>
                  navigate(`/transactions/aggregator/${row.id}`)
                }
              />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
              }}
            >
              <DataGrid
                rows={disbursed}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows.reference_no}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) =>
                  navigate(`/transactions/aggregator/${row.id}`)
                }
              />
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ReportPage;
