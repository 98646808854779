import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddTruck from "./_components/AddTruck";
import TruckDetails from "./_components/TruckDetails";
import CustomNoRowsOverlay from "../../components/NoRows";

const columns = [
  {
    field: "plat",
    headerClassName: "header",
    headerName: "Nomor Kendaraan",
    width: 80,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "model",
    headerClassName: "header",
    headerName: "Model",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "merk",
    headerClassName: "header",
    headerName: "Manufaktur",
    width: 150,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "capacity",
    headerClassName: "header",
    headerName: "Kapasitas Tangki",
    width: 100,
    flex: 1,
    renderCell: ({ value }) => (
      <Typography noWrap fontSize={14}>
        {value}
      </Typography>
    ),
  },
  {
    field: "status",
    headerClassName: "header",
    headerName: "Status",
    width: 100,
    flex: 1,
    renderCell: (params) =>
      params.value === false ? (
        <button
          style={{
            color: "#C41717",
            fontWeight: "600",
            fontSize: "12px",
            paddingX: "10px",
            paddingY: "8px",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#FBDFDF",
            marginLeft: "6px",
          }}
        >
          <span>Non-aktif</span>
        </button>
      ) : (
        <button
          style={{
            color: "#155C33",
            fontWeight: "600",
            fontSize: "12px",
            paddingX: "10px",
            paddingY: "8px",
            border: "none",
            borderRadius: "25px",
            backgroundColor: "#DEF8E9",
            marginLeft: "6px",
          }}
        >
          <span>Aktif</span>
        </button>
      ),
  },
];

const FleetManagement = ({ partnerId, fleets, getFleets }) => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  // const [fleets, setFleets] = useState([]);
  const [truckDetails, setTruckDetails] = useState({});
  const [openDetails, setOpenDetails] = useState(false);

  const seeTruckDetails = (row) => {
    setTruckDetails(row.row);
    setOpenDetails(true);
  };

  return (
    <Box flex="1">
      <AddTruck
        open={modalOpen}
        setModalOpen={setModalOpen}
        getFleets={getFleets}
        partnerId={partnerId}
      />
      <TruckDetails
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        getFleets={getFleets}
        truckDetails={truckDetails}
        partnerId={partnerId}
      />
      <Stack direction="row" justifyContent="space-between" paddingY={2}>
        <Typography variant="h6" fontWeight="500">
          Daftar Truk
        </Typography>
        <Button
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#0C78BD",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Tambah Truk
        </Button>
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
          }}
        >
          <DataGrid
            rows={fleets}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(row) => seeTruckDetails(row)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default FleetManagement;
