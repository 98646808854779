import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography, Stack } from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";
import StatusSwitch from "components/Switch";

const BankDetail = ({
  open,
  setOpenDetail,
  getBanks,
  getPartnerBanks,
  detail,
  value,
}) => {
  const [name, setName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setOpenDetail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      bank_name: bankName,
      no_rek: accountNumber,
    };

    axiosInstance
      .put(`/banks/${detail._id}`, data, authentication)
      .then(() => {
        getBanks();
        setIsEdit(false);
        setOpenDetail(false);
        Swal.fire("Selesai!", "Data Bank berhasil diperbaharui!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteBank = () => {
    setOpenDetail(false);
    Swal.fire({
      title: "Hapus data bank?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/banks/${detail._id}`, authentication)
          .then(() => {
            value === 0 ? getBanks() : getPartnerBanks();
            setIsEdit(false);
            setOpenDetail(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data Bank telah terhapus!", "success");
      } else {
        setOpenDetail(true);
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/banks/${detail._id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status bank berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getBanks();
        setOpenDetail(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setOpenDetail(false)}
      cardStyle={{ borderRadius: 2, width: 700, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Detail Rekening
            </Typography>
            <Stack direction={"row"}>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenDetail(false)}
              />
            </Stack>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {isEdit ? null : (
        <Stack direction="row" justifyContent="right" paddingX={3} paddingY={1}>
          <Stack
            sx={{
              width: "fit-content",
              borderRadius: "10px",
              backgroundColor: `${
                detail.status === true ? "#DEF8E9" : "#FBDFDF"
              }`,
            }}
            justifyContent="space-between"
            direction="row"
            px={1}
            py={1}
          >
            {detail.status === true ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#155C33",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#C41717",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Non-aktif
              </Typography>
            )}

            <Stack sx={{ py: "5px" }}>
              <StatusSwitch
                status={!!detail.status}
                changeStatus={changeStatus}
              />
            </Stack>
          </Stack>
        </Stack>
      )}

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingBottom={2}>
            {isEdit ? (
              <Stack>
                <Grid item xs={12} paddingY={2}>
                  <CustomTextField
                    name="name"
                    label="Nama Pemili Rekening"
                    placeholder={detail.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="bank_name"
                    label="Nama Bank"
                    placeholder={detail.bank_name}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                  <CustomTextField
                    name="account_number"
                    label="Nomor Rekening"
                    placeholder={detail.no_rek}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </Grid>
              </Stack>
            ) : (
              <Stack>
                <Stack direction="row" paddingY={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nama Pemilik</Typography>
                  </Grid>
                  <Grid sx={{ width: "80%" }}>
                    <Typography textTransform={"capitalize"}>
                      {value === 0
                        ? detail.name
                        : detail?.partner?.name_company}
                    </Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" paddingBottom={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nama Bank</Typography>
                  </Grid>
                  <Grid sx={{ width: "80%" }}>
                    <Typography>{detail.bank_name}</Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" paddingBottom={4} gap={2}>
                  <Grid sx={{ width: "30%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nomor Rekening</Typography>
                  </Grid>
                  <Grid sx={{ width: "80%" }}>
                    <Typography>{detail.no_rek}</Typography>
                  </Grid>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => (isEdit ? setIsEdit(false) : handleClose())}
              >
                {isEdit ? "Batal" : "Tutup"}
              </Button>
              {isEdit ? (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#0C78BC",
                    borderRadius: "20px",
                  }}
                  type="submit"
                  variant="contained"
                >
                  Simpan perubahan
                </Button>
              ) : (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#0C78BC",
                    borderRadius: "20px",
                  }}
                  variant="contained"
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Button>
              )}
              {isEdit ? null : (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#ee3f11",
                    marginLeft: "16px",
                    borderRadius: "20px",
                  }}
                  variant="contained"
                  onClick={() => deleteBank()}
                >
                  Hapus
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default BankDetail;
