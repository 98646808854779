import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Tabs, Tab } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axiosInstance from "../../utils/api/api";
import AddService from "./_components/AddService";
import ServiceDetail from "./_components/ServiceDetail";
import CustomNoRowsOverlay from "../../components/NoRows";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Services = () => {
  const [value, setValue] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  // const [services, setServices] = useState([]);
  const [wastewater, setWastewater] = useState([]);
  const [otherServices, setOtherServices] = useState([]);
  const [service, setService] = useState(null);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const columns = [
    {
      field: "service_name",
      headerClassName: "header",
      headerName: "Nama Layanan",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Typography noWrap fontSize={14}>
          {value}
        </Typography>
      ),
    },
    {
      field: "price",
      headerClassName: "header",
      headerName: "Biaya Layanan (Rp)",
      width: 100,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={{ paddingLeft: 6 }}>
          {value.toLocaleString(["ban", "id"])}
        </span>
      ),
    },
    {
      field: "status",
      headerClassName: "header",
      headerName: "Status",
      width: 100,
      flex: 1,
      renderCell: (params) =>
        params.value === false ? (
          <button
            style={{
              color: "#C41717",
              fontFamily: "Murecho",
              fontWeight: "500",
              fontSize: "12px",
              padding: "6px 5% 6px 5%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "#FBDFDF",
              marginLeft: "6px",
            }}
          >
            <span>Non-aktif</span>
          </button>
        ) : (
          <button
            style={{
              color: "#155C33",
              fontFamily: "Murecho",
              fontWeight: "500",
              fontSize: "12px",
              padding: "6px 5% 6px 5%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "#DEF8E9",
              marginLeft: "6px",
            }}
          >
            <span>Aktif</span>
          </button>
        ),
    },
  ];

  const getServices = () => {
    axiosInstance
      .get("/services/service", authentication)
      .then((res) => {
        console.log(res);
        // setServices(res.data);
        setWastewater(res.data.filter((w) => w.category === "Limbah cair"));
        setOtherServices(
          res.data.filter((os) => os.category !== "Limbah cair"),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const seeServiceDetail = (rows) => {
    setService(rows.row);
    setOpenDetail(true);
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box flex="1">
        <AddService
          open={modalOpen}
          setModalOpen={setModalOpen}
          getServices={getServices}
        />
        <ServiceDetail
          open={openDetail}
          setOpenDetail={setOpenDetail}
          getServices={getServices}
          detail={service}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Layanan
          </Typography>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#0C78BD",
              borderRadius: "20px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Data Layanan
          </Button>
        </Stack>
        <Box sx={{ paddingX: 3 }}>
          <Box sx={{ borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Limbah cair"
                {...a11yProps(0)}
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Fasilitas sanitasi"
                {...a11yProps(1)}
                sx={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
                paddingX: 3,
              }}
              variant="contained"
            >
              <DataGrid
                rows={wastewater}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) => seeServiceDetail(row)}
              />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
                paddingX: 3,
              }}
              variant="contained"
            >
              <DataGrid
                rows={otherServices}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                onRowClick={(row) => seeServiceDetail(row)}
              />
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </>
  );
};

export default Services;
