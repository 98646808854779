import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";

const AddService = ({ open, setModalOpen, getServices }) => {
  const [title, setTitle] = useState("Pengurasan Tangki Septik");
  const [price, setPrice] = useState("");
  const [isOther, setIsOther] = useState(false);
  const [category, setCategory] = useState("Limbah cair");
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const serviceList = [
    {
      _id: "S01",
      name: "Pengurasan Tangki Septik",
    },
    {
      _id: "S02",
      name: "Pengurasan Minyak/Grease",
    },
  ];

  const categories = [
    {
      _id: "C01",
      name: "Limbah cair",
    },
    {
      _id: "C02",
      name: "Fasilitas sanitasi",
    },
  ];

  const handleClose = () => {
    setTitle("Pengurasan Tangki Septik");
    setPrice("");
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      service_name: title,
      price: price,
      category: category,
    };

    axiosInstance
      .post("/services/service", data, authentication)
      .then((res) => {
        // console.log(res);
        getServices();
        setModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: "auto" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Layanan Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              {isOther ? (
                <Grid item xs={12}>
                  <CustomTextField
                    name="title"
                    label="Nama layanan"
                    placeholder="Masukkan nama layanan"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <CustomTextField
                    name="title"
                    label="Nama layanan"
                    placeholder="Masukkan layanan"
                    select
                    value={title}
                  >
                    {serviceList.map((s) => (
                      <MenuItem
                        key={s._id}
                        value={s.name}
                        onClick={() => setTitle(s.name)}
                      >
                        {s.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              )}

              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isOther}
                      onChange={() => setIsOther(!isOther)}
                    />
                  }
                  label={
                    <Typography color={"#848786"}>Layanan lainnya</Typography>
                  }
                />
              </Stack>
              <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                <CustomTextField
                  name="category"
                  label="Kategori"
                  placeholder="Pilih kategori layanan"
                  onChange={(e) => setPrice(e.target.value)}
                  select
                  value={category}
                >
                  {categories.map((c) => (
                    <MenuItem
                      key={c._id}
                      value={c.name}
                      onClick={() => setCategory(c.name)}
                    >
                      {c.name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} paddingTop={2} paddingBottom={2}>
                <CustomTextField
                  name="price"
                  label="Harga layanan"
                  placeholder="Masukkan harga layanan"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddService;
