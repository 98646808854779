import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/api/api";
import StatusSwitch from "../../../components/Switch";
import WestIcon from "@mui/icons-material/West";
import Swal from "sweetalert2";
import EditCustomer from "./EditCustomer";

const CustomerDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { id } = useParams();

  const getCustomer = async () => {
    await axiosInstance
      .get(`/customers/${id}`, authentication)
      .then((res) => {
        // console.log(res);
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const deleteCustomer = async () => {
    Swal.fire({
      title: "Hapus data pelanggan ini?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/customers/${id}`, authentication)
          .then((res) => {
            // console.log(res.data);
            navigate("/master/customers");
          })
          .catch((error) => {
            console.log(error);
          });

        Swal.fire("Selesai!", "Data pelanggan telah terhapus!", "success");
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/customers/${id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status Pelanggan berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getCustomer();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <>
      {/* <EditCustomer
        open={modalOpen}
        setModalOpen={setModalOpen}
        getFacility={getFacility}
        facility={facility}
      /> */}
      <EditCustomer
        open={modalOpen}
        setModalOpen={setModalOpen}
        customer={customer}
        getCustomer={getCustomer}
      />
      <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => navigate("/master/customers")}
          >
            Kembali
          </Button>
        </Stack>
        {user.role === "SuperAdmin" && (
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={2}
          >
            <Typography variant="h4" fontWeight="500">
              Detail Pelanggan
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#ee3f11",
                  borderRadius: "20px",
                  marginRight: 1,
                }}
                variant="contained"
                onClick={() => deleteCustomer()}
              >
                Hapus Pelanggan
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BD",
                  borderRadius: "20px",
                }}
                variant="contained"
                onClick={() => setModalOpen(true)}
              >
                Edit Pelanggan
              </Button>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="right"
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingX={3}
          paddingY={1}
          mb={2}
        >
          <Stack
            sx={{
              width: "fit-content",
              borderRadius: "10px",
              backgroundColor: `${customer.status ? "#DEF8E9" : "#FBDFDF"}`,
            }}
            justifyContent="space-between"
            direction="row"
            px={1}
            py={1}
          >
            {customer.status ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#155C33",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                  fontFamily: "Murecho",
                }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#C41717",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                  fontFamily: "Murecho",
                }}
              >
                Non-aktif
              </Typography>
            )}

            <Stack sx={{ py: "5px" }}>
              <StatusSwitch
                status={!!customer.status}
                changeStatus={changeStatus}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          paddingX={3}
          gap={2}
        >
          {/* <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={1}
          > */}
          <Stack
            sx={{
              width: "50%",
              borderRadius: "20px",
              background: "#FFF",
              padding: 2,
            }}
          >
            <Stack>
              <Typography variant="h5" fontWeight="500" sx={{ mb: 2 }}>
                Profil
              </Typography>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Nama Lengkap</Typography>
                </Grid>
                <Typography>{customer.full_name}</Typography>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Email</Typography>
                </Grid>
                <Typography>{customer.email}</Typography>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
                <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>No. HP</Typography>
                </Grid>
                <Typography>{customer.no_telpon}</Typography>
              </Stack>
            </Stack>
            {/* </Stack> */}
          </Stack>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "20px",
              background: "#FFF",
              padding: 2,
            }}
          >
            <Typography variant="h5" fontWeight="500" sx={{ mb: 2 }}>
              Alamat
            </Typography>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Provinsi</Typography>
              </Grid>
              <Typography>{customer?.address?.province}</Typography>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kota</Typography>
              </Grid>
              <Typography>{customer?.address?.city}</Typography>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kode Pos</Typography>
              </Grid>
              <Typography>{customer?.address?.postal_code}</Typography>
            </Stack>
            <Stack direction="row" sx={{ width: "90%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "250px", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Detail lainnya</Typography>
              </Grid>
              <Typography>{customer?.address?.address_detail}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default CustomerDetails;
