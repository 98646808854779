import React from "react";
import FAQ from "../pages/FAQ";

const root = "/faq";

const faq = [
  {
    path: `${root}`,
    component: <FAQ />,
    sidebar: true,
    navbar: true,
  },
];

export default faq;
