import { Grid, InputAdornment, Button, Typography, Box } from "@mui/material";
import CustomTextField from "../../components/TextField";
import React, { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Header from "./_components/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UserInvalidModal from "./_components/UserInvalidModal";
import axiosInstance from "../../utils/api/api";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import { isValidEmail } from "components/validations";

const LoginPage = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [shown, setShown] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    if (isSubmiting) {
      if (Object.keys(errors).length === 0) {
        submitLogin();
      } else {
        setIsSubmiting(false);
      }
    }
  }, [errors]);

  const triggerChangePassword = () => {
    Swal.fire({
      title: "Apakah Anda yakin ingin merubah password?",
      text: "Anda dapat merubah password Anda kembali sewaktu-waktu.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Ya, lanjutkan",
      cancelButtonText: "batal",
    })
      .then((result) => {
        const data = {
          email: registeredEmail,
        };
        if (result.isConfirmed) {
          axiosInstance.post(`/users/forget-password`, data);
          Swal.fire(
            "Selesai!",
            "Mohon periksa email Anda untuk melanjutkan",
            "success",
          );
          setForgotPassword(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitLogin = () => {
    axiosInstance
      .post(`/users/login`, form)
      .then((res) => {
        // console.log(res.data);
        localStorage.setItem("admin_data", JSON.stringify(res.data.user));
        localStorage.setItem(
          "userToken",
          JSON.stringify(res.data.access_token),
        );
        return window.location.reload();
      })
      .catch((err) => {
        Swal.fire(
          "Terjadi kesalahan!",
          "Email dan password Anda tidak cocok. Mohon periksa kembali.",
          "error",
        );
        setIsSubmiting(false);
      });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  // const validate = () => {
  //   let err = {};
  //   if (!form.email) {
  //     err.email = "email required";
  //   }
  //   if (!form.password) {
  //     err.password = "password required";
  //   }
  //   return err;
  // };

  const handleEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setEmailError("Alamat email tidak valid");
    } else {
      setEmailError(null);
    }

    handleChange(event);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          boxSizing: "",
          margin: 0,
          padding: 0,
          minHeight: "100vh",
          overflow: "hidden",
          whitespace: "nowrap",
        }}
      >
        <UserInvalidModal open={modalOpen} setModalOpen={setModalOpen} />

        {isSubmiting ? (
          <Box>
            <Grid
              container
              direction={`row`}
              justify={`center`}
              sx={{ minHeight: "100vh" }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Loader />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            width="screen"
            direction={`row`}
            justify={`center`}
            sx={{ height: "100vh" }}
            alignItems="center"
            spacing={2}
          >
            {/* <Grid item xs={12} md={6}>
                <img src={LoginImage} alt="" srcset="" />
              </Grid> */}
            <div
              style={{
                // backgroundColor: "#222",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(https://ik.imagekit.io/6tebrod5l/disanitasi/bg_mHGHshUa0.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677905234056)",
                backgroundPosition: "center",
                boxSizing: "",
                margin: 0,
                padding: 0,
                minHeight: "110vh",
                minWidth: "50%",
                overflow: "hidden",
                whitespace: "nowrap",
              }}
            />
            <Grid
              item
              xs={12}
              md={6}
              sx={{ backgroundColor: "#FFF", borderRadius: "10px" }}
            >
              <Header forgotPassword={forgotPassword} />
              {/*Form  */}
              <form>
                {forgotPassword ? (
                  <>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        type="email"
                        name="existingEmail"
                        onChange={(e) => setRegisteredEmail(e.target.value)}
                        label={errors.existingEmail ? errors.existingEmail : ""}
                        error={errors.existingEmail ? "error" : ""}
                        placeholder="Email Anda"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleEmail}
                        label={errors.email ? errors.email : ""}
                        error={errors.email ? "error" : ""}
                        placeholder="Email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {emailError && (
                        <p
                          style={{
                            color: "#F70031",
                            fontSize: "10px",
                            fontWeight: 600,
                            marginTop: "5px",
                          }}
                        >
                          {emailError}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} mb={2} px={14}>
                      <CustomTextField
                        value={form.password}
                        name="password"
                        onChange={handleChange}
                        placeholder="Password"
                        error={errors.password ? "error" : ""}
                        label={errors.password ? errors.password : ""}
                        type={shown ? "text" : "password"}
                        disabled={emailError && true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {shown ? (
                                <VisibilityIcon
                                  onClick={() => setShown(false)}
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShown(true)}
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "24px",
                    textAlign: "right",
                  }}
                  px={14}
                  py={2}
                >
                  <Typography
                    sx={{
                      color: "#222",
                      fontSize: "12px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => setForgotPassword(!forgotPassword)}
                  >
                    {forgotPassword
                      ? "Sudah punya akun? Silahkan masuk"
                      : "Lupa password?"}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} mb={2} mt={4} px={14}>
                  <Button
                    // type="submit"
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#0C78BC",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                    onClick={() =>
                      forgotPassword ? triggerChangePassword() : submitLogin()
                    }
                  >
                    {forgotPassword ? "Kirim" : "Masuk"}
                  </Button>
                </Grid>
              </form>
              {/* end Form */}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default LoginPage;
