import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";

const AddTruck = ({ open, setModalOpen, getFleets, partnerId }) => {
  const [unitId, setUnitId] = useState("");
  const [model, setModel] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [tankCapacity, setTankCapacity] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [price, setPrice] = useState("");
  const [media, setMedia] = useState(null);
  const [permit, setPermit] = useState(null);
  const [kir, setKir] = useState(null);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("unit_id", unitId);
    formData.append("model", model);
    formData.append("merk", manufacturer);
    formData.append("capacity", tankCapacity);
    formData.append("plat", vehicleNumber);
    formData.append("price", price);
    formData.append("partner_id", partnerId);
    formData.append("fleet_picture", media);
    formData.append("stnk_file", permit);
    formData.append("kir_file", kir);

    axiosInstance
      .post("/fleets/fleet", formData, authentication)
      .then((res) => {
        // console.log(res);
        getFleets();
        setModalOpen(false);
        Swal.fire("Selesai!", "Truk berhasil ditambahkan!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={open}
      handleClose={() => setModalOpen(false)}
      cardStyle={{ borderRadius: 2, width: 740, maxHeight: "85vh" }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              Tambah Truk Baru
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setModalOpen(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {/* Body */}
      <form onSubmit={handleSubmit}>
        <Box flex="1">
          <Stack paddingTop={2} alignItems="center">
            <label htmlFor="fleet_picture">
              <input
                style={{ display: "none", cursor: "pointer" }}
                id="fleet_picture"
                onChange={(e) => setMedia(e.target.files[0])}
                name="fleet_picture"
                type="file"
              />
              <Avatar
                src={media ? window.URL.createObjectURL(media) : null}
                alt="Profile"
                sx={{ height: "140px", width: "140px", cursor: "pointer" }}
              />
            </label>
          </Stack>
          <Stack paddingX={3} paddingY={2}>
            <Stack>
              <Grid item xs={12}>
                <CustomTextField
                  name="unit_id"
                  label="Unit ID"
                  value={unitId}
                  placeholder="Masukkan nama ID unit truk Anda"
                  onChange={(e) => setUnitId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="model"
                  label="Model"
                  placeholder="Masukkan model truk Anda"
                  onChange={(e) => setModel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="manufacturer"
                  label="Merek"
                  placeholder="Masukkan manufakturer Truk Anda"
                  onChange={(e) => setManufacturer(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="price"
                  label="Harga Kendaraan"
                  placeholder="Masukkan harga kendaraan"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="tank_capacity"
                  label="Kapasitas Tangki"
                  placeholder="Masukkan kapasitas tangki truk Anda"
                  onChange={(e) => setTankCapacity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} paddingTop={2}>
                <CustomTextField
                  name="vehicle_number"
                  label="Nomor Plat Kendaraaan"
                  placeholder="Masukkan nomor plate"
                  onChange={(e) => setVehicleNumber(e.target.value)}
                />
              </Grid>
              <Stack
                direction={"row"}
                width={"100%"}
                height={"fit"}
                justifyContent="center"
                gap={2}
              >
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <label htmlFor="permit">
                    <input
                      style={{ display: "none" }}
                      id="permit"
                      onChange={(e) => setPermit(e.target.files[0])}
                      name="permit"
                      type="file"
                    />
                    <Box
                      padding={3}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #6D7688",
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        color: "#6D7688",
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "full",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#6D7688",
                        }}
                      >
                        {permit !== null ? permit.name : "Upload STNK"}
                      </Typography>
                    </Box>
                  </label>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <label htmlFor="kir">
                    <input
                      style={{ display: "none" }}
                      id="kir"
                      onChange={(e) => setKir(e.target.files[0])}
                      name="kir"
                      type="file"
                    />
                    <Box
                      padding={3}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed #6D7688",
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        color: "#6D7688",
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "full",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#6D7688",
                          // display: `${media != null ? "none" : null}`,
                        }}
                      >
                        {kir !== null ? kir.name : "Upload KIR"}
                      </Typography>
                    </Box>
                  </label>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack direction="row" justifyContent="right">
              <Button
                sx={{
                  textTransform: "capitalize",
                  marginRight: "16px",
                  borderRadius: "20px",
                }}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Batal
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#0C78BC",
                  borderRadius: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Simpan
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default AddTruck;
