import React from "react";
import { FormLabel, TextField } from "@mui/material";

const CustomTextField = ({
  label,
  name,
  onChange = () => null,
  placeholder,
  ...props
}) => {
  return (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <TextField
        {...props}
        sx={label && { mt: 1 }}
        id={name}
        size="small"
        fullWidth
        placeholder={placeholder}
        onChange={onChange}
        className="inputRounded"
      />
    </>
  );
};

export default CustomTextField;
