import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/api/api";
import StatusSwitch from "../../../components/Switch";
import WestIcon from "@mui/icons-material/West";
import API_URL from "../../../utils/api/base";
import EditFacility from "./EditFacility";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";

const DetailFacility = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [facility, setFacility] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { id } = useParams();

  const getFacility = async () => {
    await axiosInstance
      .get(`/iplts/iplt/${id}`, authentication)
      .then((res) => {
        // console.log(res.data);
        setFacility(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFacility();
  }, []);

  const changeStatus = () => {
    // console.log(id, "change status");
    axiosInstance
      .patch(`/iplts/iplt/${facility._id}`, {}, authentication)
      .then((res) => {
        // console.log(res);
        getFacility();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status IPLT berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Terjadi kesalahan!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const removeFacility = () => {
    Swal.fire({
      title: "Hapus IPLT?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/iplts/iplt/${id}`, authentication)
          .then((res) => {
            // console.log(res.data);
            navigate("/master/iplt");
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data IPLT telah terhapus!", "success");
        setTimeout(() => {
          navigate("/master/iplt");
        }, 2000);
      }
    });
  };

  return (
    <>
      <EditFacility
        open={modalOpen}
        setModalOpen={setModalOpen}
        getFacility={getFacility}
        facility={facility}
      />
      {facility.length !== 0 ? (
        <Box flex="1" sx={{ backgroundColor: "#F9FAFA" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={2}
          >
            <Button
              startIcon={<WestIcon />}
              sx={{
                textTransform: "capitalize",
                color: "#6D7688",
                fontWeight: "400",
              }}
              onClick={() => navigate("/master/iplt")}
            >
              Kembali
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={2}
          >
            <Typography variant="h4" fontWeight="500">
              Detail IPLT
            </Typography>
            {user.role === "SuperAdmin" && (
              <Stack direction="row" justifyContent="space-between">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#ee3f11",
                    borderRadius: "20px",
                    marginRight: 1,
                  }}
                  variant="contained"
                  onClick={() => removeFacility()}
                >
                  Hapus IPLT
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#0C78BD",
                    borderRadius: "20px",
                  }}
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                >
                  Edit IPLT
                </Button>
              </Stack>
            )}
          </Stack>
          {user.role === "SuperAdmin" && (
            <Stack
              direction="row"
              justifyContent="right"
              paddingX={3}
              paddingY={1}
            >
              <Stack
                sx={{
                  width: "fit-content",
                  borderRadius: "10px",
                  backgroundColor: `${facility.status ? "#DEF8E9" : "#FBDFDF"}`,
                }}
                justifyContent="space-between"
                direction="row"
                px={1}
                py={1}
              >
                {facility.status ? (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#155C33",
                      fontWeight: "bold",
                      paddingTop: "1.5px",
                    }}
                  >
                    Aktif
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#C41717",
                      fontWeight: "bold",
                      paddingTop: "1.5px",
                    }}
                  >
                    Non-aktif
                  </Typography>
                )}

                <Stack sx={{ py: "5px" }}>
                  <StatusSwitch
                    status={!!facility.status}
                    changeStatus={changeStatus}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack
            paddingY={2}
            alignItems="center"
            sx={{ backgroundColor: "#F9FAFA" }}
          >
            <img
              src={`${API_URL}${facility.iplt_picture}`}
              alt="Profile"
              style={{
                height: "140px",
                width: "140px",
                cursor: "pointer",
                borderRadius: "50px",
              }}
              crossOrigin="anonymous"
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
            paddingY={1}
          >
            <Stack sx={{ width: "50%" }}>
              <Stack>
                <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                  Profil
                </Typography>
                <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                  <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Nama Perusahaan</Typography>
                  </Grid>
                  <Grid sx={{ width: "50%" }}>
                    <Typography>{facility.iplt_name}</Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                  <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>No. HP</Typography>
                  </Grid>
                  <Grid sx={{ width: "50%" }}>
                    <Typography>{facility.no_telpon}</Typography>
                  </Grid>
                </Stack>
                <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                  <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                    <Typography color={"#848786"}>Email</Typography>
                  </Grid>
                  <Grid sx={{ width: "50%" }}>
                    <Typography>{facility.email}</Typography>
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ width: "50%" }}>
              <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                Alamat
              </Typography>
              <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Kota</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.address.city}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Kecamatan</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.address.subdistrict}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Kode Pos</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.address.postal_code}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" sx={{ width: "90%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Detail lainnya</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.address.detail_address}</Typography>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            justifyContent="space-between"
            paddingX={3}
            sx={{ backgroundColor: "#F9FAFA" }}
          >
            <Stack>
              <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>
                Data Tambahan
              </Typography>
              <Stack direction="row" sx={{ width: "45%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>
                    Kapasitas pengolahan
                  </Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.capasity} &#13221;</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" sx={{ width: "45%", mb: 1 }} gap={2}>
                <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                  <Typography color={"#848786"}>Sistem pengolahan</Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography>{facility.system_processing}</Typography>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DetailFacility;
