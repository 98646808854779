import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { useParams } from "react-router-dom";
// import Swal from "sweetalert2";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/api/api";
import RejectTransaction from "./RejectTransaction";

const TransactionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState([]);
  const treatmentFee = "0";
  const [partnerFee, setPartnerFee] = useState(null);
  const [platformFee, setPlatformFee] = useState(null);
  const [orderId, setOrderId] = useState("");
  // const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAction, setIsAction] = useState("");
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getTransaction = async () => {
    await axiosInstance
      .get(`/transactions/${id}`, authentication)
      .then((res) => {
        // console.log(res.data);
        setTransaction(res.data);
        costCalculations(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const costCalculations = (item) => {
    const serviceCost = parseInt(item.total_price);
    setPartnerFee(serviceCost);
    setPlatformFee(serviceCost * 0.1);
  };

  const openActionModal = (act) => {
    setOrderId(transaction._id);
    setIsAction(act);
    setModalOpen(true);
  };

  const addCustomer = () => {
    const status = true;
    const data = {
      full_name: transaction.buyer_data.name,
      email: transaction.buyer_data.email,
      province: transaction.billing_detail.billing_province,
      city: transaction.billing_detail.billing_city,
      sub_district: transaction.billing_detail.billing_subdistrict,
      postal_code: transaction.billing_detail.billing_postal_code,
      address_detail: transaction.billing_detail.billing_address,
      no_telpon: transaction.buyer_data.no_hp,
      status: status,
      partner_id: transaction.partner_id,
    };

    axiosInstance
      .post("/customers", data, authentication)
      .then(() => {
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <>
      <RejectTransaction
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        id={orderId}
        isAction={isAction}
        setIsAction={setIsAction}
        addCustomer={addCustomer}
      />
      <Box sx={{ flexGrow: 1, backgroundColor: "#F9FAFA" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Button
            startIcon={<WestIcon />}
            sx={{
              textTransform: "capitalize",
              color: "#6D7688",
              fontWeight: "400",
            }}
            onClick={() => navigate("/transactions")}
          >
            Kembali
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingX={3}
          sx={{ backgroundColor: "#F9FAFA" }}
          paddingY={2}
        >
          <Typography variant="h4" fontWeight="500">
            Detail Transaksi
          </Typography>
          {user.role !== "SuperAdmin" ||
            ("AdminDisanitasi" && (
              <Stack direction="row" justifyContent="space-between">
                {transaction !== null
                  ? transaction.status_messsage === "Pending" && (
                      <>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#0C78BD",
                            borderRadius: "20px",
                            marginRight: 1,
                          }}
                          variant="contained"
                          onClick={() => openActionModal("Accept")}
                        >
                          Terima
                        </Button>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#EB5757",
                            borderRadius: "20px",
                            marginRight: 1,
                          }}
                          variant="contained"
                          onClick={() => openActionModal("Reject")}
                        >
                          Tolak
                        </Button>
                      </>
                    )
                  : null}
              </Stack>
            ))}
        </Stack>
        {transaction !== null && (
          <Stack
            direction="row"
            justifyContent="evenly"
            paddingX={3}
            paddingY={2}
            sx={{ gap: 2, backgroundColor: "#F9FAFA" }}
          >
            <Stack sx={{ width: "50%" }}>
              <Stack
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: "20px",
                  padding: 2,
                  margin: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500">
                  Data Pelanggan
                </Typography>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Nama
                  </Typography>
                  <Typography fontSize="16px" textTransform={"capitalize"}>
                    {transaction.buyer_data?.name}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Telepon
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.buyer_data?.no_hp}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Email
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.buyer_data?.email}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: "20px",
                  padding: 2,
                  margin: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500">
                  Alamat
                </Typography>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Provinsi
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_province}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Kota
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_city}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Kecamatan
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_subdistrict}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Kelurahan
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_ward}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    RT/RW
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_rt}/
                    {transaction.billing_detail?.billing_rw}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Alamat Lengkap
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_address}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Kode Pos
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.billing_detail?.billing_postal_code}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ width: "50%" }}>
              <Stack
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: "20px",
                  padding: 2,
                  margin: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500">
                  Data Transaksi
                </Typography>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Status
                  </Typography>
                  {transaction.status_messsage === "Batal" ? (
                    <button
                      style={{
                        color: "#C41717",
                        fontFamily: "Murecho",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "6px 5% 6px 5%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#FBDFDF",
                        marginLeft: "6px",
                      }}
                    >
                      <span>{transaction.status_messsage}</span>
                    </button>
                  ) : transaction.status_messsage === "Pending" ? (
                    <button
                      style={{
                        color: "#382A00",
                        fontFamily: "Murecho",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "6px 5% 6px 5%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#FFE79E",
                        marginLeft: "6px",
                      }}
                    >
                      <span>{transaction.status_messsage}</span>
                    </button>
                  ) : (
                    <button
                      style={{
                        color: "#155C33",
                        fontFamily: "Murecho",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "6px 5% 6px 5%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#DEF8E9",
                        marginLeft: "6px",
                      }}
                    >
                      <span>{transaction.status_messsage}</span>
                    </button>
                  )}
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    ID
                  </Typography>
                  <Typography fontSize="16px" fontWeight={600}>
                    {transaction.reference_no}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Tanggal Transaksi
                  </Typography>
                  <Typography fontSize="16px">
                    {moment(transaction.createdAt).format("LL")}
                  </Typography>
                </Stack>
                {transaction.status_messsage === "Sukses" ? (
                  <Stack
                    mt={1.5}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      color="#6D7688"
                      fontWeight="400"
                      fontSize="16px"
                    >
                      Metode Pembayaran
                    </Typography>
                    <Typography fontSize="16px">
                      {transaction.paymethod}
                    </Typography>
                  </Stack>
                ) : transaction.status_messsage === "Batal" ? (
                  <>
                    <Stack
                      mt={1.5}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        color="#6D7688"
                        fontWeight="400"
                        fontSize="16px"
                      >
                        Remark
                      </Typography>
                      <Typography fontSize="16px" textAlign={"right"}>
                        {transaction.remark}
                      </Typography>
                    </Stack>
                  </>
                ) : null}
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: "20px",
                  padding: 2,
                  margin: 1,
                }}
              >
                <Typography variant="h5" fontWeight="500">
                  Rincian Pesanan
                </Typography>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Jenis Layanan
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.service
                      ? transaction.service.service_name
                      : "Penyedotan Tangki Septik"}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Tanggal Pengerjaan
                  </Typography>
                  <Typography fontSize="16px">
                    {transaction.work_date}
                  </Typography>
                </Stack>
                <Stack mt={1.5} direction="row" justifyContent="space-between">
                  <Typography color="#6D7688" fontWeight="400" fontSize="16px">
                    Biaya Partner
                  </Typography>
                  <Typography fontSize="16px">
                    Rp{" "}
                    {parseInt(transaction?.partner_price).toLocaleString([
                      "ban",
                      "id",
                    ])}
                  </Typography>
                </Stack>
                {user.role === "SuperAdmin" && (
                  <>
                    <Stack
                      mt={1.5}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        color="#6D7688"
                        fontWeight="400"
                        fontSize="16px"
                      >
                        Biaya Pengolahan
                      </Typography>
                      <Typography fontSize="16px">
                        Rp{" "}
                        {parseInt(treatmentFee).toLocaleString(["ban", "id"])}
                      </Typography>
                    </Stack>
                    <Stack
                      mt={1.5}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        color="#6D7688"
                        fontWeight="400"
                        fontSize="16px"
                      >
                        Biaya Platform
                      </Typography>
                      <Typography fontSize="16px">
                        {/* Rp {parseInt(transaction.total_price)} */}
                        Rp{" "}
                        {transaction?.platform_price?.toLocaleString([
                          "ban",
                          "id",
                        ])}
                      </Typography>
                    </Stack>
                  </>
                )}
                <Stack
                  mt={1.5}
                  direction="row"
                  justifyContent="space-between"
                  sx={{ borderTop: "1px solid #d1d1d1", paddingTop: 2 }}
                >
                  <Typography color="#222" fontWeight="800" fontSize="20px">
                    Total
                  </Typography>
                  {user.role === "SuperAdmin" ? (
                    <Typography
                      fontSize="20px"
                      fontWeight="800"
                      sx={{ color: "#0C78BD" }}
                    >
                      Rp{" "}
                      {transaction?.total_price?.toLocaleString(["ban", "id"])}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize="20px"
                      fontWeight="800"
                      sx={{ color: "#0C78BD" }}
                    >
                      Rp{" "}
                      {transaction?.partner_price?.toLocaleString([
                        "ban",
                        "id",
                      ])}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {/* {data?.status === "Pembayaran Diterima" && (
          <Stack
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "20px",
              padding: 2,
              marginX: 4,
            }}
            mt={0.5}
          >
            <Typography variant="h5" fontWeight="500">
              Pilih Kendaraan
            </Typography>
            <Box
              sx={{
                "& .header": {
                  backgroundColor: "#F9FAFA",
                },
                "& .header-font": {
                  backgroundColor: "#F9FAFA",
                  color: "#F9FAFA",
                },
              }}
              mt={1.5}
            >
              <DataGrid
                rows={fleetData}
                columns={columns}
                pageSize={pageSize}
                autoHeight
                getRowId={(rows) => rows._id}
                header
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 25]}
                components={{
                  Toolbar: GridToolbar,
                }}
                onRowClick={(row) => selectUnit(row.row)}
              />
            </Box>
          </Stack>
        )} */}
      </Box>
    </>
  );
};

export default TransactionDetails;
