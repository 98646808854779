import React, { useEffect, useRef } from "react";

export default function CKeditor({
  onChange,
  editorLoaded,
  name,
  value,
  isDisabled,
}) {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
  }, []);

  return (
    <>
      {editorLoaded ? (
        <CKEditor
          name={name}
          editor={ClassicEditor}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
          disabled={isDisabled}
        />
      ) : (
        <div>
          <h1>Editor loading</h1>
        </div>
      )}
    </>
  );
}
