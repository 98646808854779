import React, { useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Stack,
  //   TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "utils/api/api";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const disanitasiLogo =
    "https://ik.imagekit.io/6tebrod5l/disanitasi/Logo_KiHiMY5Ba.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677907016043";
  // const test =
  //   "https://admin.disanitasi.id/forget-password?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNlMzgyZTM2LWRmNWUtNDBiNi1hZDMxLTU2ZGI0MDhmOTE0YyIsImVtYWlsIjoiYXJ5YXdkeTE2QGdtYWlsLmNvbSIsInRpbWVTdGFtcCI6IjIwMjMwNzEwMjAxMzA4IiwiaWF0IjoxNjg4OTk0Nzg4LCJleHAiOjE2ODg5OTgzODh9.l9qfeL1kB-RaI59Q3JhTJEtCobiNRULk-_AIzXnRVU4";

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async () => {
    const data = {
      token: id,
      newPassword: password,
    };
    await axiosInstance
      .post("/users/reset-password", data)
      .then((res) => {
        console.log(res);
        navigate("/");
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Password berhasil diubah!",
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(180deg, #ffffff 0%, #0e78bc 100%)",
          paddingBottom: 5,
        }}
      >
        <Stack
          // direction="row"
          justifyContent="center"
          alignItems={"center"}
          sx={{
            height: "90vh",
          }}
          paddingY={2}
          gap={4}
        >
          <Avatar
            src={disanitasiLogo}
            sx={{ borderRadius: 0, height: "100px", width: "100px" }}
          />
          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                background: "#FFF",
                minWidth: "500px",
              }}
              type={showPassword ? "password" : "text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Konfirmasi
            </InputLabel>
            <OutlinedInput
              sx={{ background: "#FFF", minWidth: "500px" }}
              type={showPassword ? "password" : "text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Button
            variant="contained"
            disableElevation
            onClick={() => changePassword()}
          >
            Ubah Password
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          gap={2}
          color={"#FFF"}
        >
          <Typography fontSize={12}>&copy; Disanitasi</Typography>
        </Stack>
      </Box>
    </>
  );
};

export default ChangePassword;
