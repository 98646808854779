import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import ModalCustom from "../../../components/Modal";
import CustomTextField from "../../../components/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../utils/api/api";
import Swal from "sweetalert2";
import StatusSwitch from "../../../components/Switch";
import API_URL from "../../../utils/api/base";

const TruckDetails = ({
  openDetails,
  setOpenDetails,
  getFleets,
  partnerId,
  truckDetails,
}) => {
  const [unitId, setUnitId] = useState("");
  const [model, setModel] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [tankCapacity, setTankCapacity] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [price, setPrice] = useState("");
  const [media, setMedia] = useState(null);
  const [permit, setPermit] = useState(null);
  const [kir, setKir] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  // const [permit, setPermit] = useState(null);
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // console.log(truckDetails);

  const handleClose = () => {
    setIsEdit(false);
    setOpenDetails(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("unit_id", unitId);
    formData.append("model", model);
    formData.append("merk", manufacturer);
    formData.append("capacity", tankCapacity);
    formData.append("plat", vehicleNumber);
    formData.append("price", price);
    formData.append("partner_id", partnerId);
    formData.append("fleet_picture", media);
    formData.append("stnk_file", permit);
    formData.append("kir_file", kir);

    axiosInstance
      .put(`/fleets/fleet/${truckDetails._id}`, formData, authentication)
      .then((res) => {
        // console.log(res);
        getFleets();
        setOpenDetails(false);
        Swal.fire("Selesai!", "Data truk berhasil diperbaharui!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteService = () => {
    setOpenDetails(false);
    Swal.fire({
      title: "Hapus data truk ini?",
      text: "Data tidak dapat dikembalikan setelah dihapus.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1365C0",
      cancelButtonColor: "#EA4D54",
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/fleets/fleet/${truckDetails._id}`, authentication)
          .then(() => {
            getFleets();
            setOpenDetails(false);
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Selesai!", "Data truk telah terhapus!", "success");
      } else {
        setOpenDetails(true);
      }
    });
  };

  const changeStatus = async () => {
    await axiosInstance
      .patch(`/fleets/fleet/${truckDetails._id}`, {}, authentication)
      .then((res) => {
        // console.log(res.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status truk berhasil diubah!",
          showConfirmButton: false,
          timer: 2000,
        });
        getFleets();
        setOpenDetails(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ModalCustom
      open={openDetails}
      handleClose={() => setOpenDetails(false)}
      cardStyle={{ borderRadius: 2, width: 740, height: isEdit ? 650 : 570 }}
    >
      {/* Header */}
      <Box>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="16px" fontWeight="600">
              {isEdit ? "Edit Data Truk" : "Detail Truk"}
            </Typography>
            <Grid item>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenDetails(false)}
              />
            </Grid>
          </Stack>
        </Box>
        <Divider />
      </Box>

      {isEdit ? null : (
        <Stack direction="row" justifyContent="right" paddingX={3} paddingY={1}>
          <Stack
            sx={{
              width: "fit-content",
              borderRadius: "10px",
              backgroundColor: `${truckDetails.status ? "#DEF8E9" : "#FBDFDF"}`,
            }}
            justifyContent="space-between"
            direction="row"
            px={1}
            py={1}
          >
            {truckDetails.status ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#155C33",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#C41717",
                  fontWeight: "bold",
                  paddingTop: "1.5px",
                }}
              >
                Non-aktif
              </Typography>
            )}

            <Stack sx={{ py: "5px" }}>
              <StatusSwitch
                status={!!truckDetails.status}
                changeStatus={changeStatus}
              />
            </Stack>
          </Stack>
        </Stack>
      )}

      {/* Body */}
      {isEdit ? (
        <form onSubmit={handleSubmit}>
          <Box flex="1">
            <Stack paddingTop={2} alignItems="center">
              <label htmlFor="fleet_picture">
                <input
                  style={{ display: "none", cursor: "pointer" }}
                  id="fleet_picture"
                  onChange={(e) => setMedia(e.target.files[0])}
                  name="fleet_picture"
                  type="file"
                />
                <Avatar
                  src={media ? window.URL.createObjectURL(media) : null}
                  alt="Profile"
                  sx={{ height: "140px", width: "140px", cursor: "pointer" }}
                />
              </label>
            </Stack>
            <Stack paddingX={3} paddingY={2}>
              <Stack>
                <Grid item xs={12}>
                  <CustomTextField
                    name="unit_id"
                    label="Unit ID"
                    placeholder={truckDetails.unit_id}
                    onChange={(e) => setUnitId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="model"
                    label="Model"
                    placeholder={truckDetails.model}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="manufacturer"
                    label="Merek"
                    placeholder={truckDetails.merk}
                    onChange={(e) => setManufacturer(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="price"
                    label="Harga Kendaraan"
                    placeholder={truckDetails.price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="tank_capacity"
                    label="Kapasitas Tangki"
                    placeholder={truckDetails.capacity}
                    onChange={(e) => setTankCapacity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <CustomTextField
                    name="vehicle_number"
                    label="Nomor Plat Kendaraaan"
                    placeholder={truckDetails.plat}
                    onChange={(e) => setVehicleNumber(e.target.value)}
                  />
                </Grid>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  height={"fit"}
                  justifyContent="center"
                  gap={2}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <label htmlFor="permit">
                      <input
                        style={{ display: "none" }}
                        id="permit"
                        onChange={(e) => setPermit(e.target.files[0])}
                        name="permit"
                        type="file"
                      />
                      <Box
                        padding={3}
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px dashed #6D7688",
                          borderRadius: "5px",
                          backgroundColor: "#fafafa",
                          color: "#6D7688",
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "full",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#6D7688",
                          }}
                        >
                          {permit !== null ? permit.name : "Upload STNK"}
                        </Typography>
                      </Box>
                    </label>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <label htmlFor="kir">
                      <input
                        style={{ display: "none" }}
                        id="kir"
                        onChange={(e) => setKir(e.target.files[0])}
                        name="kir"
                        type="file"
                      />
                      <Box
                        padding={3}
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px dashed #6D7688",
                          borderRadius: "5px",
                          backgroundColor: "#fafafa",
                          color: "#6D7688",
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "full",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#6D7688",
                            // display: `${media != null ? "none" : null}`,
                          }}
                        >
                          {kir !== null ? kir.name : "Upload KIR"}
                        </Typography>
                      </Box>
                    </label>
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "20px",
                  }}
                  variant="outlined"
                  onClick={() => handleClose()}
                >
                  Tutup
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    marginRight: "16px",
                    borderRadius: "20px",
                  }}
                  variant="outlined"
                  onClick={() => setIsEdit(false)}
                >
                  Batalkan
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#0C78BC",
                    borderRadius: "20px",
                  }}
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </Stack>
            </Box>
          </Box>
        </form>
      ) : (
        <Stack>
          <Stack paddingX={3} paddingY={2}>
            {/* <Typography variant="h6" fontWeight="500" sx={{ mb: 2 }}>
            Data Umum
          </Typography> */}
            <Stack paddingY={2} alignItems="center">
              {/* <label htmlFor="fleet_picture">
                <input
                  style={{ display: "none", cursor: "pointer" }}
                  id="fleet_picture"
                  onChange={(e) => setMedia(e.target.files[0])}
                  name="fleet_picture"
                  type="file"
                /> */}
              <img
                src={`${API_URL}${truckDetails.fleet_picture}`}
                alt="Profile"
                crossOrigin="anonymous"
                style={{
                  height: "140px",
                  width: "140px",
                  borderRadius: "100px",
                }}
              />
              {/* </label> */}
            </Stack>
            <Stack direction="row" sx={{ width: "100%", mb: 2, mt: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Nomor Kendaraan</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{truckDetails.plat}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "100%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Tipe</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{truckDetails.model}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "100%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Manufaktur</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{truckDetails.merk}</Typography>
              </Grid>
            </Stack>
            <Stack direction="row" sx={{ width: "100%", mb: 2 }} gap={2}>
              <Grid sx={{ width: "50%", borderRight: "2px solid #D3D3D3" }}>
                <Typography color={"#848786"}>Kapasitas Tangki</Typography>
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <Typography>{truckDetails.capacity}</Typography>
              </Grid>
            </Stack>
          </Stack>
          <Box>
            <Divider />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="right">
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#ee3f11",
                    borderRadius: "20px",
                  }}
                  variant="contained"
                  onClick={() => deleteService()}
                >
                  Hapus
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "20px",
                    marginLeft: "16px",
                  }}
                  variant="outlined"
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Button>
              </Stack>
            </Box>
          </Box>
        </Stack>
      )}
    </ModalCustom>
  );
};

export default TruckDetails;
