import React from "react";
import Banks from "../pages/Banks";
import Payments from "../pages/Payments";
import ReportPage from "../pages/Report";
import TransactionList from "../pages/Transactions";
import TransactionDetails from "../pages/Transactions/_components/TransactionDetails";
import AggregatorDetails from "pages/Report/_components/AggregatorDetails";

const root = "/transactions";

const transactions = [
  {
    path: `${root}`,
    component: <TransactionList />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/banks`,
    component: <Banks />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/payments`,
    component: <Payments />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/list/:id`,
    component: <TransactionDetails />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/aggregator`,
    component: <ReportPage />,
    sidebar: true,
    navbar: true,
  },
  {
    path: `${root}/aggregator/:id`,
    component: <AggregatorDetails />,
    sidebar: true,
    navbar: true,
  },
];

export default transactions;
