import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/api/api";
import AddFacility from "./_components/AddFacility";
import CustomNoRowsOverlay from "../../components/NoRows";

const TreatmentPlants = () => {
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [facilities, setFacilities] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin_data"));
  const token = JSON.parse(localStorage.getItem("userToken"));
  const authentication = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const columns = [
    // {
    //   field: "facility_id",
    //   headerClassName: "header",
    //   headerName: "ID",
    //   width: 80,
    //   renderCell: ({ value }) => <Typography noWrap fontSize={14}>
    //   {value}
    // </Typography>,
    // },
    {
      field: "iplt_name",
      headerClassName: "header",
      headerName: "Nama",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Typography noWrap fontSize={14}>
          {value}
        </Typography>
      ),
    },
    {
      field: "email",
      headerClassName: "header",
      headerName: "Email",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Typography noWrap fontSize={14}>
          {value}
        </Typography>
      ),
    },
    {
      field: "address",
      headerClassName: "header",
      headerName: "Alamat",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={{ paddingLeft: 6 }}>{value.city}</span>
      ),
    },
    {
      field: "no_telpon",
      headerClassName: "header",
      headerName: "Telepon",
      width: 100,
      flex: 1,
      renderCell: ({ value }) => (
        <Typography noWrap fontSize={14}>
          {value}
        </Typography>
      ),
    },
    {
      field: "capasity",
      headerClassName: "header",
      headerName: "Kapasitas",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={{ paddingLeft: 6 }}>{value} &#13221;</span>
      ),
    },
    {
      field: "system_processing",
      headerClassName: "header",
      headerName: "Sistem pengolahan",
      width: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Typography noWrap fontSize={14}>
          {value}
        </Typography>
      ),
    },
    {
      field: "status",
      headerClassName: "header",
      headerName: "Status",
      width: 100,
      flex: 1,
      renderCell: (params) =>
        params.value === false ? (
          <button
            style={{
              color: "#C41717",
              fontFamily: "Murecho",
              fontWeight: "500",
              fontSize: "12px",
              padding: "6px 5% 6px 5%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "#FBDFDF",
              marginLeft: "6px",
            }}
          >
            <span>Non-aktif</span>
          </button>
        ) : (
          <button
            style={{
              color: "#155C33",
              fontFamily: "Murecho",
              fontWeight: "500",
              fontSize: "12px",
              padding: "6px 5% 6px 5%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "#DEF8E9",
              marginLeft: "6px",
            }}
          >
            <span>Aktif</span>
          </button>
        ),
    },
  ];

  const getFacilities = () => {
    axiosInstance
      .get("/iplts/iplt", authentication)
      .then((res) => {
        // console.log(res);
        setFacilities(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <Box flex="1">
      <AddFacility
        open={modalOpen}
        setModalOpen={setModalOpen}
        getFacilities={getFacilities}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={3}
        sx={{ backgroundColor: "#F9FAFA" }}
        paddingY={2}
      >
        <Typography variant="h4" fontWeight="500">
          Daftar Instalasi Pengolahan
        </Typography>
        {user.role === "SuperAdmin" && (
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#0C78BD",
              borderRadius: "20px",
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            Tambah Data IPLT
          </Button>
        )}
      </Stack>
      <Stack>
        <Box
          sx={{
            "& .header": {
              backgroundColor: "#F9FAFA",
            },
            "& .header-font": {
              backgroundColor: "#F9FAFA",
              color: "#F9FAFA",
            },
            padding: 3,
          }}
        >
          <DataGrid
            rows={facilities}
            columns={columns}
            pageSize={pageSize}
            autoHeight
            getRowId={(rows) => rows._id}
            header
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 15, 25]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(row) => navigate(`/master/iplt/${row.id}`)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default TreatmentPlants;
