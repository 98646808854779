import { Box } from "@mui/material";
import React from "react";

function CardSummary({ id, label, value, icon, rate, total, type }) {
  return (
    <Box
      sx={{
        width: 660,
        height: "fit-content",
        padding: 2,
        background: "#FFFFFF",
        color: "#222",
        "&:hover": {
          backgroundColor: "primary.white",
          opacity: [0.9, 0.8, 0.7],
        },
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        border: "1px solid #eee",
        fontFamily: "Murecho",
      }}
      key={id}
      gap={2}
    >
      <span
        style={{
          color: "#0E78BC",
          fontSize: "35px",
          paddingTop: "7px",
        }}
      >
        {icon}
      </span>
      <Box
        sx={{
          display: "grid",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <label style={{ fontSize: "12px", margin: 0 }}>{label}</label>
        <p style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
          Rp {value}
        </p>
        <p style={{ fontSize: "12px", marginTop: "1px", marginBottom: 0 }}>
          {" "}
          <span
            style={{ color: type ? "#198754" : "#FF3333", fontWeight: 500 }}
          >
            {((rate / total) * 100).toFixed(2)}%
          </span>{" "}
          ({rate} transaksi)
        </p>
      </Box>
    </Box>
  );
}

export default CardSummary;
